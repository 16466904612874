import { ethers } from "ethers";
import { encodeFunctionData } from "viem";
import { USDC_ADDRESS, SpaceXDepositContract } from "../../contracts/index";
import USDC_ABI from "../../contracts/usdc.json";
import deposit_ABI from "../../contracts/deposits.json";

interface SendDepositParams {
  client: any;
  ethersProvider: any;
  depositAmount: any;
  depositAddress: string;
  setIsLoading: any;
}

interface DepositResult {
  txHash: string;
  depositAmount: string;
  fromAddress: string;
  toAddress: string;
  setIsLoading: any

}

export async function sendDeposit({
  client,
  ethersProvider,
  depositAmount,
  depositAddress,
  setIsLoading,

}: SendDepositParams): Promise<DepositResult> {
  if (!client || !ethersProvider || isNaN(Number(depositAmount)) || Number(depositAmount) <= 0) {
    console.error("Invalid input amount for deposit");
    return {
      txHash: "",
      depositAmount: "0",
      fromAddress: "",
      toAddress: "",
      setIsLoading: ""
    };
  }

  try {
    const depositAmountBigNumber = ethers.utils.parseUnits(depositAmount.toString(), 6);
    const calls = [
      {
        to: USDC_ADDRESS,
        data: encodeFunctionData({
          abi: USDC_ABI,
          functionName: "approve",
          args: [depositAddress, depositAmountBigNumber]
        })
      },
      {
        to: depositAddress,
        data: encodeFunctionData({
          abi: deposit_ABI,
          functionName: "deposit",
          args: [depositAmountBigNumber]
        })
      }
    ] as const;

    const gasEstimates = {
      preVerificationGas: "2000000",
      verificationGasLimit: "2000000",
      callGasLimit: "2000000"
    };

    const txParams = {
      account: client?.account,
      calls,
      ...gasEstimates
    };

    const txHash = await client.sendTransaction(txParams);
    console.log("Transaction Hash:", txHash);
    setIsLoading(false);
    const receipt = await ethersProvider.getTransactionReceipt(txHash);
    let confirmedAmount = "0";
    let fromAddress = "";
    let toAddress = "";

    // Parse the receipt logs for the Transfer event
    for (const log of receipt.logs) {
      // Check if this is a Transfer event (standard ERC20 Transfer event topic)
      if (log.topics[0] === "0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef") {
        fromAddress = "0x" + log.topics[1].slice(26).toLowerCase();
        toAddress = "0x" + log.topics[2].slice(26).toLowerCase();

        // Check if the transfer was to our deposit contract
        if (toAddress === depositAddress.toLowerCase()) {
          const transferAmount = ethers.BigNumber.from(log.data);
          confirmedAmount = ethers.utils.formatUnits(transferAmount, 6);
          console.log(`Deposit confirmed:
            Amount: ${confirmedAmount} USDC
            From: ${fromAddress}
            To: ${toAddress}
          `);
        }
      }
    }

    return {
      txHash,
      depositAmount: confirmedAmount,
      fromAddress,
      toAddress,
      setIsLoading
    };
  } catch (error) {
    console.error("Error during deposit:", error);
    setIsLoading(false);
    throw error;
  }
}
