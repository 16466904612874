import ErrorOutline from "../../assets/images/error-outline.png";
import { CompanyDataType } from "constants/constants";
import { formatToDollar } from "common";
import { PrimaryInvestmentType } from "types";
import React from "react";

interface InsufficientFundsProps {
  usdcBalance: string;
}
const InsufficientFunds: React.FC<InsufficientFundsProps> = ({ usdcBalance }) => {
  return (
    <div className="relative px-6 py-4 border rounded mt-4">
      <img src={ErrorOutline} style={{ position: "absolute", top: 18, left: 6, width: 12, height: 12 }} />
      <div className="text-xs font-bold"> Insufficient Funds</div>
      <div className="text-xs">
        You currently have {formatToDollar(usdcBalance)} Edit your order or deposit more funds in order to make your
        purchase
      </div>
      <div className="text-xs text-blue-600"> Make a Deposit </div>
    </div>
  );
};

interface InsufficientSharesProps {
  companyBalances: { [key: string]: string }; // Adjust type if needed
  selectedCompany: CompanyDataType; // Adjust type if needed
}
const InsufficientShares: React.FC<InsufficientSharesProps> = ({ companyBalances, selectedCompany }) => {
  return (
    <div className="relative px-6 py-4 border rounded mt-4">
      <img src={ErrorOutline} style={{ position: "absolute", top: 18, left: 6, width: 12, height: 12 }} />
      <div className="text-xs font-bold"> Not Enough Shares to Sell</div>
      <div className="text-xs"> You currently have {companyBalances[selectedCompany.title]} shares </div>
    </div>
  );
};

interface OverMaxAllocationProps {
  goal: string; // Adjust type if needed
  amountIn: string; // Adjust type if needed
}
const OverMaxAllocation: React.FC<OverMaxAllocationProps> = ({ goal, amountIn }) => {
  return (
    <div className="relative px-6 py-4 border rounded mt-4">
      <img src={ErrorOutline} style={{ position: "absolute", top: 18, left: 6, width: 12, height: 12 }} />
      <div className="text-xs font-bold"> Not Enought Allocation Space Left</div>
      <div className="text-xs">
        {" "}
        You are trying to buy ${amountIn} worth of shares, but there is only ${goal} of space left{" "}
      </div>
    </div>
  );
};

interface ErrorMessageProps {
  isButtonDisabled: boolean;
  action: string;
  denomination: string;
  inputValue: any;
  usdcBalance: any;
  selectedCompany: any;
  usdcAmount: any;
  idx: number;
  sharesAmount: any;
  companyBalances: any;
}

const ErrorMessages: React.FC<ErrorMessageProps> = ({
  isButtonDisabled,
  action,
  denomination,
  inputValue,
  usdcBalance,
  usdcAmount,
  idx,
  selectedCompany,
  sharesAmount,
  companyBalances
}) => {
  if (
    isButtonDisabled &&
    action === "buy" &&
    ((denomination === "dollars" && Number(inputValue) > Number(usdcBalance)) ||
      (denomination === "shares" && Number(usdcAmount[idx]) > Number(usdcBalance)))
  ) {
    return <InsufficientFunds usdcBalance={usdcBalance} />;
  } else if (
    isButtonDisabled &&
    action === "sell" &&
    ((denomination === "shares" && Number(inputValue) > Number(companyBalances[selectedCompany.title])) ||
      (denomination === "dollars" && Number(sharesAmount[idx]) > Number(companyBalances[selectedCompany.title])))
  ) {
    return <InsufficientShares selectedCompany={selectedCompany} companyBalances={companyBalances} />;
  } else {
    return null;
  }
};

export { InsufficientFunds, InsufficientShares, OverMaxAllocation, ErrorMessages };
