import React from "react";
import PortfolioImage from "../../assets/images/portolio-sample-pic.png";
import texture from "../../assets/images/Texture.png";
import { useWindowSize } from "../../hooks";
import CompanySlider from "./CompanySlider";
import { useNavigate } from "react-router-dom";
import Prinx from "../../assets/images/prinx.svg";
const Masthead: React.FC = () => {
  const { isMobile } = useWindowSize();
  const navigate = useNavigate();

  const handleJoinWaitlist = () => {
    navigate("/waitlist");
  };
  return (
    <section className="relative">
      <div className="ml-0 lg:ml-20 grid grid-cols-1 lg:grid-cols-2 items-center min-h-screen px-4 relative lg:-top-10 ">
        <div className="relative lg:absolute lg:top-12 lg:left-8 flex items-center gap-2 justify-center lg:justify-start mt-8">
          <img src={Prinx} alt="Prinx Logo" className="w-10 lg:w-12 filter brightness-0 invert" />
          <span className="tracking-widest font-thin" style={{ fontSize: 32 }}>
            Prinx
          </span>
        </div>
        {/* Left Column: Text Content */}
        <div className="flex flex-col items-center lg:items-start justify-center text-center lg:text-left mb-12 p-2">
          <div className="mt-12 bg-transparent text-white px-4 border py-1 border-white-700 rounded-full text-sm uppercase font-medium tracking-wider mb-6">
            Invest like the 1%
          </div>
          <h1 className="text-4xl sm:text-6xl font-bold mb-6 tracking-widest font-thin">
            Invest & Trade
            <br />
            Pre-IPO Companies
          </h1>
          <p className="text-white text-lg mb-8 max-w-2xl pr-4">
            Prinx gives you secure & easy access to private investment opportunities so you can diversify your
            portfolio.
          </p>
          <button
            onClick={handleJoinWaitlist}
            style={{ background: "#436FE7", position: "relative", top: isMobile ? 20 : 0 }}
            className="text-white px-6 py-3 rounded text-lg hover:bg-blue-700 transition relative sm:-top-10"
          >
            Join the waitlist
          </button>
        </div>
        {/* Right Column: Image */}
        <div className="relative lg:h-[410px] flex items-center justify-center mb-48 lg:mb-0">
          <img
            style={{
              boxShadow: "0 0 15px 1px rgba(67, 111, 230, 0.8)"
            }}
            src={PortfolioImage}
            alt="Dashboard Placeholder"
            className="rounded-lg shadow-lg lg:h-full lg:w-auto lg:object-cover lg:object-left"
          />
        </div>
        {/* Bottom Section */}
      </div>
      <div className="absolute bottom-0 left-0 w-full text-white py-3 text-center">
        <div className="relative">
          {/* Background Texture */}
          <div
            style={{
              background: `url('${texture}') repeat`,
              backgroundSize: "50%",
              zIndex: 9
            }}
            className="absolute inset-0"
          ></div>
          {/* Gradient Overlay */}
          <div
            style={{
              background: "linear-gradient(to right, rgba(147, 134, 244, 0.8) 0%, rgba(67, 111, 230, 0.8) 100%)"
            }}
            className="absolute inset-0"
          ></div>
          {/* Content */}
          <div className="grid grid-cols-2 items-center" style={{ height: "120px", zIndex: 10 }}>
            {/* Left Column */}
            <div style={{ height: "100%", zIndex: 10 }} className="flex flex-col justify-center items-start px-4">
              <h2 className="tracking-widest text-md lg:text-2xl font-bold text-white text-left">
                Invest in private companies at no minimum
              </h2>
            </div>

            {/* Right Column */}
            <CompanySlider />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Masthead;
