import { CompanyDataType } from "constants/constants";
import Tooltip from "./PriceExplanationTooltip";

interface TradeWidgetDollarShareLabelsProps {
  action: string;
  companyData: CompanyDataType[];
  idx: number;
  pricePerShare: string;
}

const TradeWidgetDollarShareLabels: React.FC<TradeWidgetDollarShareLabelsProps> = ({
  action,
  companyData,
  idx,
  pricePerShare
}) => {
  return (
    <div className="flex flex-row justify-between mt-4 relative">
      {action == "buy" ? (
        <>
          <div className="relative">
            Price Per Share <Tooltip />
          </div>

          <div className="">
            {companyData[idx].sharePrice < 0 ? "Sold out" : `$${Number(pricePerShare).toFixed(2)}`}
          </div>
        </>
      ) : (
        <>
          <div className="">Price Per Share</div>
          <div className="">
            {companyData[idx].sellSharePrice < 0 ? "No Liquidity" : `$${Number(pricePerShare).toFixed(2)}`}
          </div>
        </>
      )}
    </div>
  );
};

export default TradeWidgetDollarShareLabels;
