import { Buffer } from "buffer";

import { Routes, Route, BrowserRouter } from "react-router-dom";
import { Roll_A_B } from "experiments/layout-experiment";
import Layout from "./components/Layout/Layout";
import Home from "./components/Home/Home";
import Profile from "./components/Profile/Profile";
import Trade from "./components/Trade/Trade";
import Company from "./components/Company/Company";
import NotHereYet from "./components/NotHereYet/NotHereYet";
import Waitlist from "./components/Waitlist/Waitlist";
import Settings from "./components/Settings/Settings";
import { CompanyDataType, PrimaryInvestments } from "./constants/constants";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

import mixpanel from "mixpanel-browser";
mixpanel.init("9954cda356a51017fd6e598959d05c65", {
  debug: true,
  track_pageview: true,
  persistence: "localStorage"
});

import "styles/App.css";
import { usePrivy } from "@privy-io/react-auth";
import { useContext, useEffect, useState } from "react";
import LandingPage from "components/LandingPage/LandingPage";
import SharePriceContext from "sharePriceContext";

function generateCompanyRoutes(data: any) {
  return data.map((c: any) => {
    return <Route key={`${c.uri}`} path={`${c.uri}`} element={<Company />} />;
  });
}
function generateSyndicateRoutes() {
  return PrimaryInvestments.map((c) => {
    return <Route key={`${c.uri}`} path={`${c.uri}`} element={<Company />} />;
  });
}

function App() {
  if (!window.Buffer) window.Buffer = Buffer;
  const { t } = useTranslation(); // Hook for translations
  const { companyData: CompanyData } = useContext(SharePriceContext);

  const { user } = usePrivy();
  const [AB, setAB] = useState(() => Roll_A_B(i18next.t));
  const [companyRoutes, setCompanyRoutes] = useState();
  useEffect(() => {
    if (user) {
      mixpanel.identify(user.id);
      mixpanel.people.set({
        $email: user.email?.address,
        smartWalletAddress: user.smartWallet?.address
        // Add anything else about the user here
      });
    }
  }, [user]);
  useEffect(() => {
    generateCompanyRoutes(CompanyData);
  }, [CompanyData]);
  const syndicateRoutes = generateSyndicateRoutes();
  return (
    <BrowserRouter>
      <Routes>
        <Route path={"/"} element={<Layout />}>
          <Route index element={<LandingPage />} />
          <Route path="/primary" element={<Home />} />
          <Route path="/primary/:company" element={<Company />} />
          <Route path="waitlist" element={<Waitlist str={AB.str} ab={AB.ab} />} />
          <Route path="profile" element={<Profile />} />
          <Route path="not-here-yet" element={<NotHereYet />} />
          <Route path="settings" element={<Settings />} />
          <Route path="trade" element={<Trade data={CompanyData as CompanyDataType[]} />} />
          <Route path="/trade/:company" element={<Company />} />

          {companyRoutes}
          {syndicateRoutes}
          <Route path="*" element={<Home />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
