import React, { useEffect, useState } from "react";
import ReactModal from "react-modal";
import { formatToDollar } from "common";
const customStyles: ReactModal.Styles = {
  content: {
    position: "fixed" as const,
    top: "20vh",
    left: "0",
    right: "0",
    bottom: "auto",
    height: "40vh",
    borderBottomLeftRadius: "1rem",
    borderBottomRightRadius: "1rem",
    margin: "0",
    padding: "1rem",
    transform: "translateY(100%)", // Start off-screen
    transition: "transform 0.25s ease, opacity 0.25s ease", // Animate up and fade-in
    opacity: 0 // Start hidden
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    transition: "opacity 0.25s ease", // Fade-in overlay
    opacity: 0
  }
};

const ConfirmationModal = ({
  setTransactionSuccess,
  selectedCompany,
  isAnimating,
  action,
  receipt,
  denomination,
  setInputValue
}: {
  setTransactionSuccess: (value: boolean) => void;
  selectedCompany: any; // Replace `any` with the proper type
  isAnimating: boolean;
  action: string;
  receipt: any;
  denomination: string;
  setInputValue: any;
}) => {
  const [styles, setStyles] = useState(customStyles);
  useEffect(() => {
    if (isAnimating) {
      // Update styles to animate modal into view
      setStyles((prevStyles: any) => ({
        ...prevStyles,
        content: {
          ...prevStyles.content,
          transform: "translateY(0)", // Animate into view
          opacity: 1 // Make visible
        },
        overlay: {
          ...prevStyles.overlay,
          opacity: 1 // Fade in overlay
        }
      }));
    }
  }, [isAnimating]);
  const determineConfirmationText = function () {
    console.log("Receipt In Modal", receipt);
    if (action == "buy" && denomination == "dollars") {
      return (
        <p className="text-gray-700">
          You have successfully purchased {receipt.quantityOut} shares in{" "}
          <strong>{selectedCompany?.title || "your chosen company"}</strong> for {formatToDollar(receipt.quantityIn)}.
        </p>
      );
    } else if (action == "buy" && denomination == "shares") {
      return (
        <p className="text-gray-700">
          You have successfully purchased {receipt.quantityOut} shares in{" "}
          <strong>{selectedCompany?.title || "your chosen company"}</strong> for {formatToDollar(receipt.quantityIn)}.
        </p>
      );
    } else if (action == "sell" && denomination == "dollars") {
      return (
        <p className="text-gray-700">
          You have successfully sold {receipt.quantityIn} share(s) of{" "}
          <strong>{selectedCompany?.title || "your chosen company"}</strong> for {formatToDollar(receipt.quantityOut)}.
        </p>
      );
    } else {
      //sell and shares
      return (
        <p className="text-gray-700">
          You have successfully sold {receipt.quantityIn} share(s) of{" "}
          <strong>{selectedCompany?.title || "your chosen company"}</strong> for {formatToDollar(receipt.quantityOut)}{" "}
        </p>
      );
    }
  };
  const handleClose = function () {
    setTransactionSuccess(false);
    setInputValue("");
  };
  const text = determineConfirmationText();
  return (
    <ReactModal isOpen={true} style={styles}>
      <div className="text-center flex flex-col items-center">
        <div className="bg-green-100 rounded-full p-4 mb-4 flex items-center justify-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-10 w-10 text-green-600"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
          </svg>
        </div>
        <h2 className="text-2xl font-semibold text-green-700 mb-2">Transaction Successful!</h2>
        {text}
        <div className="mt-4 flex gap-2">
          <button
            onClick={() => handleClose()}
            className="bg-green-600 text-white px-4 py-2 rounded hover:bg-green-500 transition"
          >
            Done
          </button>
        </div>
      </div>
    </ReactModal>
  );
};

export default ConfirmationModal;
