import React, { FC, useState } from "react";
import { formatToDollar } from "common";

interface BalanceSummaryProps {
  usdcBalance: string;
}

const BalanceSummary: FC<BalanceSummaryProps> = ({ usdcBalance }) => {
  return (
    <div className="bg-white p-4 flex-col h-full w-full">
      <div className="text-m"> Current Balance </div>
      <div className="text-2xl mt-4"> {formatToDollar(usdcBalance)} </div>
      <div className="text-m text-blue-700 mt-2"> Transfer Funds </div>
      <div className="text-gray-400 mt-8">
        Your balance allows you to invest in offerings and receive distributions. This is not factored into your
        portfolio value
      </div>
    </div>
  );
};
export default BalanceSummary;
