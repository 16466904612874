export function generateDollarShareLabels(
  action: string,
  denomination: string,
  sharesAmount: string,
  usdcAmount: string,
  idx: number,
  companyData: any
) {
  let label = "";
  let value = "";

  if (action === "buy") {
    if (denomination === "dollars") {
      label = "Shares";
      value = `~${sharesAmount[idx] || "0"}`;
    } else {
      label = "Cost";
      value = `~$${(parseFloat(usdcAmount[idx]) || 0).toFixed(2)}`;
    }
  } else {
    // sell
    if (denomination === "dollars") {
      label = "Shares Sold";
      value = `~${sharesAmount[idx] || "0"}`;
    } else {
      label = "Return";
      value = `~$${(parseFloat(usdcAmount[idx]) || 0).toFixed(2)}`;
    }
  }
  if (companyData[idx] && companyData[idx].sharePrice < 0) {
    return <div className="flex flex-row justify-between mt-4"></div>;
  }
  return (
    <div className="flex flex-row justify-between mt-4">
      <div className="">{label}</div>
      <div className="">{value}</div>
    </div>
  );
}

export function determineIfButtonIsDisabled2(
  inputValue: string,
  action: string,
  denomination: string,
  usdcBalance: string,
  companyBalances: any,
  selectedCompany: any,
  usdcAmount: any,
  idx: number,
  sharesAmount: any,
  boolean?: boolean
) {
  if (boolean) return boolean;
  if (!inputValue || Number(inputValue) <= 0) return true;
  if (
    action === "buy" && 
    denomination === "dollars" && 
    Number(inputValue) > Number(usdcBalance) ||
    Number(inputValue) < 1
  ) return true;

  if (
    action === "sell" &&
    denomination === "shares" &&
    Number(inputValue) > Number(companyBalances[selectedCompany.title]) 
  )
    return true;
  if (action === "buy" && denomination === "shares" && Number(usdcAmount[idx]) > Number(usdcBalance)) return true;
  if (
    action === "sell" &&
    denomination === "dollars" &&
    Number(sharesAmount[idx]) > Number(companyBalances[selectedCompany.title])
  )
    return true;

  return false;
}
