import TickerImage from "../../assets/images/ticker-img.png";
import texture from "../../assets/images/Texture.png";
import MoneyDrop from "../../assets/images/money-drop.svg";
import SwapArrows from "../../assets/images/swap-arrows.png";
import MoneyDown from "../../assets/images/money-down.png";
import Calendar from "../../assets/images/calendar.svg";
import { useNavigate } from "react-router-dom";
const EffortlesslyTrade: React.FC = () => {
  const navigate = useNavigate();

  const handleJoinWaitlist = () => {
    navigate("/waitlist");
  };
  return (
    <section className="flex flex-col lg:flex-row items-center justify-between text-white px-6 lg:px-20">
      {/* Left Column: Gradient Image */}
      <div className="rounded relative w-full md:w-2/3 lg:w-1/2 text-center md:text-left mt-8 pt-4 md:pt-12 px-4 md:px-16">
        <div
          style={{
            background: `url('${texture}') repeat`,
            zIndex: 1,
            backgroundSize: "50%"
          }}
          className="absolute rounded inset-0"
        ></div>
        <div
          style={{
            borderRadius: "10px",
            background: "linear-gradient(to right, rgba(147, 134, 244, 0.8) 0%, rgba(67, 111, 230, 0.8) 100%)"
          }}
          className="absolute inset-0"
        ></div>
        <div style={{ zIndex: 10 }} className="relative flex items-center justify-center h-full">
          <img
            src={TickerImage} // Replace with actual image path
            alt="Investment Widget"
            className="rounded-lg shadow-lg"
            style={{ maxHeight: 500 }}
          />
        </div>
      </div>
      {/* Right Column: Text Content */}
      <div className="w-full lg:w-1/2 mt-8 md:mt-0 md:pl-12 md:text-left">
        <h1 className="text-3xl md:text-5xl font-semibold mt-16 tracking-widest font-thin">
          Effortlessly trade Pre-IPO shares
        </h1>
        <p className="text-lg md:text-xl mb-6 mt-6 lg:mt-12">
          Prinx offers trading of private equity assets with secure and transparent trading technology, breaking down
          the barriers of entry for everyday investors.
        </p>
        <ul className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
          <li className="flex items-center space-x-2">
            <img src={MoneyDrop} />
            <span>Deeper liquidity</span>
          </li>
          <li className="flex items-center space-x-2">
            <img src={MoneyDown} />

            <span>Lower transaction costs</span>
          </li>
          <li className="flex items-center space-x-2">
            <img src={SwapArrows} />
            <span>Instant trades</span>
          </li>
          <li className="flex items-center space-x-2">
            <img src={Calendar} />
            <span>Markets are live 24/7</span>
          </li>
        </ul>
        <button
          onClick={handleJoinWaitlist}
          style={{ background: "#436FE7" }}
          className="px-6 py-3 bg-[#3A2AEF] hover:bg-[#6842FF] rounded-md text-white text-lg font-medium"
        >
          Join the waitlist
        </button>
      </div>
    </section>
  );
};
export default EffortlesslyTrade;
