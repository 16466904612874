import texture from "../../assets/images/Texture.png";
import { useNavigate } from "react-router-dom";






const DidYouKnow: React.FC = () => {
  const navigate = useNavigate();
  const handleJoinWaitlist = () => {
    navigate("/waitlist");
  };

  return (
    <section className="relative flex flex-col md:flex-row items-center justify-between px-8 py-16 md:py-24 md:px-32 bg-gradient-to-br from-[#A7F3D0] to-[#93C5FD] text-gray-900">
      <div
        style={{
          background: `url('${texture}') repeat`,
          backgroundSize: "20%",
          zIndex: 1
        }}
        className="absolute rounded inset-0"
      ></div>

      {/* Left: Placeholder Box */}

      {/* Right: Text Content */}
      <div
        className="grid grid-cols-1 md:grid-cols-1 gap-8 items-center"
        style={{ height: "100%" }} // Ensures the grid container takes the full height
      >
        {/* Left: Placeholder Image */}
       {/*} <div style={{ zIndex: 2 }} className="w-full h-full bg-gray-200 rounded-lg shadow-lg"></div>

        {/* Right: Text Content */}
        <div style={{ zIndex: 2 }} className="w-full text-center md:text-left flex flex-col justify-center">
          {/* Subtitle */}
          <div className="mb-4">
            <span className="inline-block px-4 py-2 text-sm font-semibold text-white uppercase border rounded-full shadow">
              Did You Know?
            </span>
          </div>

          {/* Main Heading */}
          <h2 className="text-4xl md:text-5xl font-bold leading-tight mb-6">
            Big endowments invest
            <br />
            <span className="text-blue-500">28.2%</span> in private equity
            <br /> and venture capital
          </h2>

          {/* Subtext */}
          <p className="text-lg text-gray-700 mb-8">
            And now you can too—without all the hassle! Don’t miss out on your chance to diversify your portfolio like
            the $3 billion+ endowments.
          </p>

          {/* Call to Action Button */}
          <button 
           onClick={handleJoinWaitlist}
          className="px-6 py-3 bg-blue-500 text-white text-lg font-medium rounded-md shadow-md hover:bg-blue-600 w-full md:max-w-xs">
         
  Join the waitlist
</button>
        </div>
      </div>
    </section>
  );
};

export default DidYouKnow;
