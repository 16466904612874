import React from "react";

interface CompanyBioProps {
  bio: string;
}
const Company: React.FC<CompanyBioProps> = ({ bio }) => {
  const [isExpanded, setIsExpanded] = React.useState(false);

  // Function to toggle between truncated and full text
  const toggleText = () => setIsExpanded(!isExpanded);

  return (
    <div>
      {/* Show truncated or full text based on `isExpanded` */}
      <p>{isExpanded ? bio : `${bio.slice(0, 400)}...`}</p>

      {/* Button to toggle text expansion */}
      <button onClick={toggleText} className="text-blue-500 underline">
        {isExpanded ? "Read Less" : "Read More"}
      </button>
    </div>
  );
};

export default Company;
// return <div className="flex w-full"> {bio} </div>;
