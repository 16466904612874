import { Trade } from "@uniswap/v3-sdk";
import React, { FC, useState } from "react";
import { useNavigate } from "react-router-dom";

const handleNavigation = (navigate: any, route: string) => {
  navigate(route); // Specify the route you want to navigate to
};

function generateTableTsx(navigate: any, companyData: any) {
  return companyData.map((investment: any) => {
    return (
      <tr
        className="cursor-pointer border-t"
        key={investment.title}
        onClick={() => handleNavigation(navigate, investment.uri)}
      >
        <td className="py-2 flex flex-row items-center">
          <div className="p-1 mr-2">
            <img src={investment.image} />
          </div>
          <div className="flex flex-col">
            <div className="text-l"> {investment.title}</div>
            <div className="text-xs"> {investment.shareType}</div>
          </div>
        </td>
        <td className="py-2"> {investment.sharePrice < 0 ? "SOLD OUT" : `$${investment.sharePrice.toFixed(2)}`}</td>
        <td className="py-2">
          {investment.sellSharePrice < 0 ? "SOLD OUT" : `$${investment.sellSharePrice.toFixed(2)}`}
        </td>
        <td className="py-2"> {">"} </td>
      </tr>
    );
  });
}
interface TradeTableProps {
  companyData: any;
}
const TradeTable: FC<TradeTableProps> = ({ companyData }) => {
  const navigate = useNavigate();

  const tableTsx = generateTableTsx(navigate, companyData);

  return (
    <table className="min-w-full table-auto border-collapse">
      <thead>
        <tr>
          <th className="text-left py-2 text-gray-400 text-xs" style={{ width: "40%" }}>
            Firm
          </th>
          <th className="text-left py-2 text-gray-400 text-xs" style={{ width: "20%" }}>
            Buy Price
          </th>
          <th className="text-left py-2 text-gray-400 text-xs" style={{ width: "30%" }}>
            Sell Price
          </th>
        </tr>
      </thead>
      <tbody>{tableTsx}</tbody>
    </table>
  );
};
export default TradeTable;
