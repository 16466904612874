import { useEffect, useState, useContext } from "react";
import { getQuote, getOutQuote } from "./getQuote";
import { CompanyDataType } from "../../constants/constants";
import TradeTable from "./TradeTable";
import TradeWidget from "./TradeWidget";
import { useWindowSize } from "hooks";
import BlockChainContext from "blockchainContext";
import SharePriceContext from "sharePriceContext";

interface TradeComponentProps {
  data: CompanyDataType[]; // An array of TradeProps
}

const Trade: React.FC<TradeComponentProps> = () => {
  // set state variables

  const { companyData: CompanyData } = useContext(SharePriceContext);
  const [sharesAmount, setSharesAmount] = useState<string>("");
  const [usdcAmount, setUsdcAmount] = useState<string>("");
  const [action] = useState<string>("buy");
  const [denomination] = useState<string>("dollars");
  const [inputValue] = useState("");
  const [selectedCompany] = useState<CompanyDataType>(CompanyData[0]);
  const [setPPS] = useState<string>("0");
  const { isLargeScreen } = useWindowSize();
  const { provider, usdcBalance, companyBalances, setUsdcBalance, smartWalletAddress } = useContext(BlockChainContext);
  //get provider, USDC balance, and company balances

  useEffect(() => {
    if (CompanyData && selectedCompany) {
      const idx = CompanyData.findIndex((co: CompanyDataType) => co.title == selectedCompany.title);
      if ((action === "buy" && denomination === "dollars") || (action === "sell" && denomination === "shares")) {
        getQuote(provider, inputValue, idx, selectedCompany, setSharesAmount, setUsdcAmount, action, setPPS);
      } else {
        getOutQuote(provider, inputValue, idx, selectedCompany, setSharesAmount, setUsdcAmount, action, setPPS);
        console.log("Shares", sharesAmount);
        console.log("Usdc", usdcAmount);
      }
    }
  }, [selectedCompany, action, denomination, inputValue, CompanyData, provider, sharesAmount, usdcAmount, setPPS]);

  return (
    <div className="p-4" style={{ width: "100%", backgroundColor: "#F5F7FD", minHeight: "100vh" }}>
      <h1 className="p-4 text-4xl"> Trade </h1>
      <h4 className="p-4 text-xl"> Available Pre-IPO Stocks</h4>
      <div className={`grid ${!isLargeScreen ? "grid-cols-1" : "grid-cols-3"} gap-4`}>
        {/* First item (TradeTable) */}
        <div className={`col-span-2 p-4 ${!isLargeScreen ? "order-2" : "order-1"}`}>
          <div className="overflow-x-auto">
            <TradeTable companyData={CompanyData} />
          </div>
        </div>
        {/* Second item (TradeWidget) */}
        <div className={`${!isLargeScreen ? "order-1" : "order-2"}`}>
          {CompanyData.length && provider ? (
            <TradeWidget
              usdcBalance={usdcBalance}
              smartWalletAddress={smartWalletAddress}
              provider={provider}
              companyBalances={companyBalances}
              setUsdcBalance={setUsdcBalance}
              company={null}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Trade;
