import { useLocation } from "react-router-dom";

const NotHereYet: React.FC = () => {
  // Sample investment data
  const location = useLocation();
  const receivedData = location.state;
  console.log("Received Data Here?", receivedData);
  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-blue-100 p-10">
      <h1> Not Here Yet!</h1>
      {receivedData.geo.country} -- is not allowed
    </div>
  );
};

export default NotHereYet;
