import React, { useEffect, useState } from "react";
import ReactModal from "react-modal";
import { formatToDollar } from "common";
const customStyles: ReactModal.Styles = {
  content: {
    zIndex: 99,
    borderBottomLeftRadius: "1rem",
    borderBottomRightRadius: "1rem",
    margin: "0",
    padding: "1rem",
    transform: "translateY(100%)", // Start off-screen
    transition: "transform 0.25s ease, opacity 0.25s ease", // Animate up and fade-in
    opacity: 0 // Start hidden
  },
  overlay: {
    zIndex: 9,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    transition: "opacity 0.25s ease", // Fade-in overlay
    opacity: 0
  }
};

const PPModal = ({ onClose, isAnimating }: { isAnimating: boolean; onClose: any }) => {
  const [styles, setStyles] = useState(customStyles);
  useEffect(() => {
    if (isAnimating) {
      // Update styles to animate modal into view
      setStyles((prevStyles: any) => ({
        ...prevStyles,
        content: {
          ...prevStyles.content,
          transform: "translateY(0)", // Animate into view
          opacity: 1 // Make visible
        },
        overlay: {
          ...prevStyles.overlay,
          opacity: 1 // Fade in overlay
        }
      }));
    }
  }, [isAnimating]);

  return (
    <ReactModal isOpen={true} style={styles}>
      <div className="bg-gray-100 text-gray-800 px-6 py-10 max-w-4xl mx-auto relative">
        <div onClick={() => onClose()} className="absolute pointer-cursor cursor-pointer">
          {" "}
          X{" "}
        </div>
        <h1 className="text-3xl font-bold text-center mb-8">Privacy Policy</h1>
        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-2">1. Introduction</h2>
          <p className="mb-2">Your privacy is important to us. This Privacy Policy explains:</p>
          <ul className="list-disc list-inside pl-4">
            <li>What information we collect and why we collect it.</li>
            <li>How we use that information.</li>
            <li>The choices we offer, including how to access and update information.</li>
          </ul>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-2">2. Information We Collect</h2>
          <h3 className="font-semibold mb-1">a. Personal Information</h3>
          <p className="mb-2">
            When you create an account or use our Services, we may collect personal information that can be used to
            identify you, such as:
          </p>
          <ul className="list-disc list-inside pl-4">
            <li>
              <strong>Contact Information:</strong> Email address, phone number.
            </li>
            <li>
              <strong>Verification Information:</strong> Documents and data collected during our Know Your Customer
              (KYC) and Anti-Money Laundering (AML) processes.
            </li>
          </ul>

          <h3 className="font-semibold mb-1">b. Wallet Information</h3>
          <ul className="list-disc list-inside pl-4">
            <li>
              <strong>Wallet Address:</strong> When you create a self-custody wallet using our third-party provider,
              Privy, a wallet address is generated. This address is publicly visible on the blockchain but does not
              contain any personal identifying information.
            </li>
            <li>
              <strong>Login Credentials:</strong> Privy manages your login credentials (email, phone number, or Gmail
              account) and has access to the associated wallet address. Please review Privy&apos;s Privacy Policy to
              understand how they handle your information.
            </li>
          </ul>

          <h3 className="font-semibold mb-1">c. Usage Data</h3>
          <p className="mb-2">We may collect information about how you access and use the Services, such as:</p>
          <ul className="list-disc list-inside pl-4">
            <li>
              <strong>Device Information:</strong> IP address, browser type, operating system.
            </li>
            <li>
              <strong>Log Information:</strong> Dates and times of logins, transactions, and other activities.
            </li>
          </ul>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-2">3. How We Use Your Information</h2>
          <p className="mb-2">We use the collected information for various purposes:</p>
          <ul className="list-disc list-inside pl-4">
            <li>
              <strong>To Provide Services:</strong> Facilitate transactions, authenticate users, and provide customer
              support.
            </li>
            <li>
              <strong>Compliance:</strong> Comply with legal obligations, including KYC/AML requirements.
            </li>
            <li>
              <strong>Improvement:</strong> Analyze usage to enhance our Services and user experience.
            </li>
            <li>
              <strong>Communication:</strong> Send updates, security alerts, and administrative messages.
            </li>
          </ul>
        </section>

        <footer className="mt-10 text-center text-sm text-gray-500">
          If you have any questions, please contact us at <strong>info@prinx.io</strong>.
        </footer>
        <button className="bg-gray-200 p-4" onClick={() => onClose()}>
          {" "}
          Close{" "}
        </button>
      </div>
    </ReactModal>
  );
};

export default PPModal;
