import React, { createContext, useContext, useEffect, useState } from "react";
import BlockChainContext from "blockchainContext";
import { ethers, BigNumber } from "ethers";
import deposit_ABI from "./contracts/deposits.json";
import QuoterV2Json from "@uniswap/swap-router-contracts/artifacts/contracts/lens/QuoterV2.sol/QuoterV2.json";
import { USDC_ADDRESS } from "./contracts";
import { SELL_FEE_PERCENTAGE, BUY_FEE_PERCENTAGE } from "./constants/feeConfig";
import { CompanyDataType, PrimaryInvestments } from "constants/constants";
import { PrimaryInvestmentType } from "types";
import { fetchAssets } from "calls";
const QUOTER_CONTRACT_ADDRESS = "0x3d4e44Eb1374240CE5F1B871ab261CD16335B76a";

interface SharePriceContextType {
  ethersProvider: ethers.providers.Web3Provider | null;
  companyData: any;
  primaryCompanyData: PrimaryInvestmentType[];
}

export const SharePriceContext = createContext<SharePriceContextType>({
  ethersProvider: null,
  companyData: [],
  primaryCompanyData: PrimaryInvestments
});

export async function getPricePerShare(
  ethersProvider: ethers.providers.Web3Provider,
  idx: number,
  assets: any
): Promise<any> {
  const quoterContract = new ethers.Contract(QUOTER_CONTRACT_ADDRESS, QuoterV2Json.abi, ethersProvider);

  // Use 1 share as input
  const oneShare = ethers.utils.parseUnits("1", 6);
  const tokenAddress = assets[idx].address;
  const pricePerShare = "";
  // Calculate sell fee
  const sell_feeAmount = oneShare.mul(SELL_FEE_PERCENTAGE * 100).div(100);
  const amountAfterSellFee = oneShare.sub(sell_feeAmount);
  const objToReturn = { buy: "", sell: "" };
  let readableSellQuote;
  try {
    const quoteSell = await quoterContract.callStatic.quoteExactInputSingle({
      tokenIn: tokenAddress,
      tokenOut: USDC_ADDRESS,
      fee: 3000,
      amountIn: amountAfterSellFee,
      sqrtPriceLimitX96: 0
    });

    readableSellQuote = BigNumber.isBigNumber(quoteSell) ? quoteSell : quoteSell?.[0];
    const actuallyReadableThough = ethers.utils.formatUnits(readableSellQuote, 6);
    objToReturn.sell = actuallyReadableThough;
  } catch (error) {
    console.warn(`Error Quote Sell: ${assets[idx].title} No cash available in pool`);
    objToReturn.sell = "-1";
  }
  // Quote for 1 share output
  try {
    const quoteBuy = await quoterContract.callStatic.quoteExactOutputSingle({
      tokenIn: USDC_ADDRESS,
      tokenOut: tokenAddress,
      fee: 3000,
      amount: oneShare,
      sqrtPriceLimitX96: 0
    });
    const readableBuyQuote = BigNumber.isBigNumber(quoteBuy) ? quoteBuy : quoteBuy?.[0];
    const priceWithFee = readableBuyQuote.mul(100).div(100 - BUY_FEE_PERCENTAGE * 100);

    // Calculate price per share
    //pricePerShare = priceWithFee.add(readableSellQuote).div(2);
    objToReturn.buy = ethers.utils.formatUnits(priceWithFee, 6);

    return objToReturn;
  } catch (error) {
    console.warn(`Error Quote Sell: ${assets[idx].title} is Sold Out `);
    objToReturn.buy = "-1";
    return objToReturn;
  }
}

const fetchAllocations = async (
  ethersProvider: ethers.providers.Web3Provider,
  setPrimaryCompanyData: React.Dispatch<React.SetStateAction<PrimaryInvestmentType[]>>,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  smartWalletAddress: string
) => {
  if (!ethersProvider || !ethersProvider._isProvider) {
    return;
  }
  try {
    const goalValue: { [key: string]: string } = {};
    const usdcDepositValue: { [key: string]: string } = {};
    const modifiedInvestments = await Promise.all(
      PrimaryInvestments.map(async (option) => {
        if (option.wallet_address) {
          const contract = new ethers.Contract(option.wallet_address, deposit_ABI, ethersProvider);

          // Fetch allocation amount
          const allocationRaw = await contract.allocationAmount();
          const allocationValue = ethers.utils.formatUnits(allocationRaw, 6);
          const goal = parseFloat(allocationValue);

          // Fetch user deposit amount
          const userDepositRaw = await contract.getUserBalance(smartWalletAddress);
          const userDepositValue = ethers.utils.formatUnits(userDepositRaw, 6);
          const userDeposit = parseFloat(userDepositValue);
          //console.log(`Successfully fetched user deposit for ${option.title}:`, userDeposit);

          // Fetch USDC goal amount
          const usdcDepositRaw = await contract.totalDeposited();
          const usdcDepositValue = ethers.utils.formatUnits(usdcDepositRaw, 6);
          const deposited = parseFloat(usdcDepositValue);

          // Return a modified copy of the option object
          return { ...option, goal, deposited, userDeposit };
        }
        // Return the option as is if there's no wallet_address
        return option;
      })
    );
    // Update the state with the modified array
    setPrimaryCompanyData(modifiedInvestments);
  } catch (error) {
    if (!(error instanceof Error) || !error.message.includes("invalid provider")) {
      console.error("Critical error in fetchAllocations:", error);
    }
  } finally {
    setIsLoading(false);
  }
};

export const SharePriceContextProvider = ({ children }: { children: React.ReactNode }) => {
  const { ethersProvider, smartWalletAddress } = useContext(BlockChainContext);
  const [isLoading, setIsLoading] = useState(true);
  const [companyData, setCompanyData] = useState<any[]>([]);
  const [assets, setAssets] = useState<any>(null);
  const [primaryCompanyData, setPrimaryCompanyData] = useState<any[]>(PrimaryInvestments);
  useEffect(() => {
    const getAssets = async () => {
      const assets = await fetchAssets();
      setAssets(assets);
    };
    const fetchSharePrices = async () => {
      if (!ethersProvider) return;
      //call to get company data from server
      const result = await Promise.all(
        assets.map(async (co: any, idx: number) => {
          const prices = await getPricePerShare(ethersProvider, idx, assets);
          return {
            ...co,
            sharePrice: Number(prices.buy),
            sellSharePrice: Number(prices.sell) // Update the sharePrice
          };
        })
      );
      setCompanyData(result);
    };
    if (!assets) getAssets();
    if (assets) fetchSharePrices();
    if (ethersProvider) {
      fetchAllocations(ethersProvider, setPrimaryCompanyData, setIsLoading, smartWalletAddress);
    }
  }, [ethersProvider, assets]);

  const value = {
    ethersProvider,
    companyData,
    primaryCompanyData
  };
  return <SharePriceContext.Provider value={value}>{children}</SharePriceContext.Provider>;
};

export default SharePriceContext;
