import { FC, useContext, useEffect, useState } from "react";

import { useLinkAccount, usePrivy } from "@privy-io/react-auth";

import InvestmentCard from "components/Home/InvestmentCard";

import AssetTable from "./AssetTable";
import MarketTable from "./MarketTable";
import PendingTransactionsTable from "./PendingTransactionTable";

import PortfolioSummary from "./PortfolioSummary";
import BalanceSummary from "./BalanceSummary";
import BlockChainContext from "blockchainContext";

import { useWindowSize } from "hooks";
import SharePriceContext from "sharePriceContext";
import UserSteps from "components/UserSteps";

async function fund(func: any, addr: any) {
  await func(addr);
}

const Profile: FC = () => {
  const { authenticated, linkPhone, user } = usePrivy();
  const { usdcBalance, companyBalances } = useContext(BlockChainContext);
  const { isSmallScreen, isMobile } = useWindowSize();
  const { companyData, primaryCompanyData } = useContext(SharePriceContext);
  const investment = primaryCompanyData[1];
  useEffect(() => {
    const phoneAccount = user?.linkedAccounts.find((account) => account.type === "phone");
    if (authenticated && !phoneAccount) {
      linkPhone();
    }
  }, [authenticated]);
  return (
    <div style={{ width: "100%", backgroundColor: "#F5F7FD" }} className="p-8">
      <h1 className="text-4xl mb-10">
        {" "}
        Welcome Back
        {(user?.linkedAccounts[0] as any)?.name ? `, ${(user?.linkedAccounts[0] as any)?.name}` : ""}
      </h1>
      <div className="flex gap-4">
        {isSmallScreen ? (
          // Single Grid Layout for Small Screens
          <div className="w-full">
            <UserSteps usdcBalance={usdcBalance} />
            <div className="grid grid-cols-2 gap-4">
              <div className={`flex ${isMobile ? "col-span-2" : "col-span-1"}`}>
                <PortfolioSummary />
              </div>
              <div className={`flex ${isMobile ? "col-span-2" : "col-span-1"}`}>
                <BalanceSummary usdcBalance={usdcBalance} />
              </div>
              <div className="bg-white p-4 col-span-2">
                <div className="text-m mb-4"> Assets </div>
                <AssetTable companyBalances={companyBalances} companyData={companyData} />
              </div>
              <div className="bg-white mt-4 col-span-2">
                <div className="p-4 flex flex-col h-full">
                  <div className="text-m"> Pending Transactions </div>
                  <PendingTransactionsTable />
                </div>
              </div>
              {/* Contents from the Right Grid */}
              <div className="bg-white p-4 col-span-2">
                <div className="flex flex-col">
                  <div className="text-lg"> Featured in The Primary Market</div>
                  <div className="text-sm text-gray-400">
                    Fund the next generation of world-class startups, projects, and more
                  </div>
                  <InvestmentCard investment={investment} />
                </div>
              </div>
              <div className="bg-white p-4 col-span-2">
                <div className="text-xl"> Markets </div>
                <MarketTable companyData={companyData} />
              </div>
            </div>
          </div>
        ) : (
          // Two-Column Layout for Larger Screens
          <>
            {/* Left Grid Container (66% width) */}
            <div className="w-2/3">
              <UserSteps usdcBalance={usdcBalance} />

              <div className="grid grid-cols-2 gap-4">
                <PortfolioSummary />
                <BalanceSummary usdcBalance={usdcBalance} />
                <div className="bg-white p-4 col-span-2">
                  <div className="text-m mb-4"> Assets </div>
                  <AssetTable companyBalances={companyBalances} companyData={companyData} />
                </div>
              </div>
              <div className="bg-white mt-4">
                <div className="p-4 flex flex-col h-full">
                  <div className="text-m"> Pending Transactions </div>
                  <PendingTransactionsTable />
                </div>
              </div>
            </div>
            {/* Right Grid Container (33% width) */}
            <div className="w-1/3">
              <div className="grid grid-rows-2 gap-4">
                <div className="bg-white">
                  <div className="p-4 flex flex-col">
                    <div className="text-lg"> Featured in The Primary Market</div>
                    <div className="text-sm text-gray-400">
                      Fund the next generation of world-class startups, projects, and more
                    </div>
                    <InvestmentCard investment={investment} />
                  </div>
                </div>
                <div className="bg-white p-4">
                  <div className="text-xl"> Markets </div>
                  <MarketTable companyData={companyData} />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Profile;
// <iframe
//   src="https://buy.onramper.dev?apiKey=pk_test_01JBDFJ5E9M6BSM76N77YFMKWJ"
//   title="Onramper Widget"
//   height="630px"
//   width="420px"
//   allow="accelerometer; autoplay; camera; gyroscope; payment; microphone"
// />;
// const handleTransferUSDC = async () => {
//   try {
//     if (!client) {
//       console.error('Smart wallet client not initialized');
//       return;
//     }

//     const recipientAddress = '0x31416725C76716eEAB12FB990A64819eCac3D1BA' as `0x${string}`;
//     const amount = BigInt(1000); // 0.001 USDC (6 decimals)

//     const calls = [
//       {
//         to: USDC_ADDRESS as `0x${string}`,
//         data: encodeFunctionData({
//           abi: USDC_ABI,
//           functionName: 'approve',
//           args: [recipientAddress, amount],
//         }),
//       },
//       {
//         to: USDC_ADDRESS as `0x${string}`,
//         data: encodeFunctionData({
//           abi: USDC_ABI,
//           functionName: 'transfer',
//           args: [recipientAddress, amount],
//         }),
//       },
//     ] as const;

// // Get gas estimates using the imported function
// const gasEstimates = await estimateUserOperationGas({
//   sender: client.account.address,
//   nonce: await client.account.getNonce(),
//   initCode: '0x',
//   callData: calls[0].data,
//   callGasLimit: '0x0',
//   verificationGasLimit: '0x0',
//   preVerificationGas: '0x0',
//   maxFeePerGas: '0x0',
//   maxPriorityFeePerGas: '0x0',
//   signature: '0x'.padEnd(130, '0'),
//   paymasterAndData: '0x',
// });

// const txParams = {
//   account: client.account,
//   calls,
//   ...gasEstimates
// };

//     const txHash = await client.sendTransaction(txParams);
//     console.log('Transaction hash:', txHash);
//   } catch (error) {
//     console.error('Error in handleTransferUSDC:', error);
//     if (error instanceof Error) {
//       console.error('Error name:', error.name);
//       console.error('Error message:', error.message);
//       console.error('Error stack:', error.stack);
//     }
//   }
// };

/* <WalletHTML /> */

/* {smartWalletAddress && (
        <div style={styles.wallet}>
          <h4>Smart Wallet Address:</h4>
          <p>{smartWalletAddress}</p>
        </div>
      )} */
