import React, { FC, useState } from "react";
import { formatToDollar } from "common";
const fundingRounds: FundingRoundType[] = [
  {
    key: 1,
    date: "2023-01-01",
    round: "Series A",
    amountRaised: 100000,
    lastValuation: 100000,
    investors: ["2B Global Capital"]
  },
  {
    key: 2,
    date: "2023-06-01",
    round: "Series A",
    amountRaised: 1000000,
    lastValuation: 1000000,
    investors: ["2B Global Capital", "Venture Garage"]
  },
  {
    key: 3,
    date: "2024-06-01",
    round: "Series A",
    amountRaised: 10000000,
    lastValuation: 100000000,
    investors: ["2B Global Capital", "Venture Garage", "Hummingbird Ventures"]
  }
];

interface FundingRoundType {
  key: number;
  date: string;
  round: string;
  amountRaised: number;
  lastValuation: number;
  investors: string[];
}

function generateTableTsx() {
  return fundingRounds.map((item) => {
    return (
      <tr key={item.key} className="items-center">
        <td className="py-2">{item.date}</td>
        <td className="py-2">{item.round}</td>
        <td className="py-2">${item.amountRaised.toLocaleString()}</td>
        <td className="py-2">${item.lastValuation.toLocaleString()}</td>
        <td className="py-2">{item.investors.join(",")}</td>
      </tr>
    );
  });
}

const FundingRoundsTable: FC = () => {
  const tableTsx = generateTableTsx();
  return (
    <table className="min-w-full table-auto border-collapse">
      <thead>
        <tr>
          <th className="text-left py-2 text-gray-400 text-xs" style={{ width: "20%" }}>
            Date
          </th>
          <th className="text-left py-2 text-gray-400 text-xs" style={{ width: "20%" }}>
            Round
          </th>
          <th className="text-left py-2 text-gray-400 text-xs" style={{ width: "20%" }}>
            Amount Raised
          </th>
          <th className="text-left py-2 text-gray-400 text-xs" style={{ width: "20%" }}>
            Last Valuation
          </th>
          <th className="text-left py-2 text-gray-400 text-xs" style={{ width: "20%" }}>
            Investors
          </th>
        </tr>
      </thead>
      <tbody>{tableTsx}</tbody>
    </table>
  );
};
export default FundingRoundsTable;
