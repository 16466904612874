import React, { useEffect, useState } from "react";
import ReactModal from "react-modal";
import { formatToDollar } from "common";
const customStyles: ReactModal.Styles = {
  content: {
    zIndex: 99,
    borderBottomLeftRadius: "1rem",
    borderBottomRightRadius: "1rem",
    margin: "0",
    padding: "1rem",
    transform: "translateY(100%)", // Start off-screen
    transition: "transform 0.25s ease, opacity 0.25s ease", // Animate up and fade-in
    opacity: 0 // Start hidden
  },
  overlay: {
    zIndex: 9,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    transition: "opacity 0.25s ease", // Fade-in overlay
    opacity: 0
  }
};

const TermsOfService = ({ onClose, isAnimating }: { isAnimating: boolean; onClose: any }) => {
  const [styles, setStyles] = useState(customStyles);
  useEffect(() => {
    if (isAnimating) {
      // Update styles to animate modal into view
      setStyles((prevStyles: any) => ({
        ...prevStyles,
        content: {
          ...prevStyles.content,
          transform: "translateY(0)", // Animate into view
          opacity: 1 // Make visible
        },
        overlay: {
          ...prevStyles.overlay,
          opacity: 1 // Fade in overlay
        }
      }));
    }
  }, [isAnimating]);

  return (
    <ReactModal isOpen={true} style={styles}>
      <div className="bg-gray-50 text-gray-800 px-6 py-10 max-w-4xl mx-auto">
        <h1 className="text-3xl font-bold text-center mb-8">Prinx Exchange Terms and Conditions</h1>
        <div onClick={() => onClose()} className="absolute pointer-cursor cursor-pointer">
          {" "}
          X{" "}
        </div>
        <p className="text-sm text-gray-500 mb-6 text-center">Last Updated: 11/19/2024</p>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-2">1. Introduction</h2>
          <p>
            Welcome to Prinx Exchange (&apos;Prinx,&apos; &apos;we,&apos; &apos;us,&apos; or &apos;our&apos;). These
            Terms and Conditions (&apos;Terms&apos;) govern your access to and use of our platform, services, and
            applications (collectively, the &apos;Services&apos;). By accessing or using our Services, you agree to be
            bound by these Terms.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-2">2. Eligibility</h2>
          <p>To use our Services, you must:</p>
          <ul className="list-disc list-inside pl-4">
            <li>Be at least 18 years old or the age of majority in your jurisdiction.</li>
            <li>
              Successfully complete our Know Your Customer (KYC) and Anti-Money Laundering (AML) verification processes.
            </li>
            <li>Comply with all applicable laws and regulations.</li>
          </ul>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-2">3. Account Registration and Security</h2>
          <ul className="list-disc list-inside pl-4">
            <li>
              <strong>Self-Custody Wallet:</strong> Users are required to create a self-custody wallet using our
              third-party provider, Privy. Your use of Privy&apos;s services is subject to their terms and conditions,
              and we encourage you to review them carefully.
            </li>
            <li>
              <strong>Login Credentials:</strong> You can access your wallet using your email, phone number, or Gmail
              account. You are responsible for maintaining the confidentiality of your login credentials.
            </li>
            <li>
              <strong>Soul-Bound Whitelist NFT:</strong> Upon successful KYC/AML verification, a non-transferable
              (soul-bound) whitelist NFT will be minted into your self-custody wallet, granting you access to interact
              with pre-IPO tokens.
            </li>
            <li>
              <strong>Security:</strong> You are solely responsible for safeguarding your wallet, private keys, and any
              other security credentials. Prinx is not liable for any loss or damage arising from your failure to
              protect your account and wallet.
            </li>
          </ul>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-2">4. Description of Services</h2>
          <ul className="list-disc list-inside pl-4">
            <li>
              <strong>Tokenized SPV Exchange:</strong> Prinx facilitates the buying and selling of tokenized shares of
              pre-IPO companies using USD Coin (USDC), a stablecoin.
            </li>
            <li>
              <strong>Transactions:</strong> All token exchanges utilize Uniswap smart contracts. Transactions are
              executed on the blockchain and are subject to its operational procedures and limitations.
            </li>
            <li>
              <strong>No Custodial Services:</strong> Prinx does not custody any user assets, including USDC or pre-IPO
              tokens. All assets are held in your self-custody wallet.
            </li>
            <li>
              <strong>Third-Party Services:</strong> Prinx integrates services from third-party on/off ramp providers
              for depositing and withdrawing funds. We are not responsible for the actions or omissions of these third
              parties.
            </li>
          </ul>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-2">5. Risks and Disclaimers</h2>
          <ul className="list-disc list-inside pl-4">
            <li>
              <strong>Investment Risk:</strong> Investing in tokenized pre-IPO shares involves significant risk,
              including the potential loss of your entire investment. Prices can be volatile and may fluctuate widely.
            </li>
            <li>
              <strong>Liquidity Risk:</strong> Private securities are known to be illiquid. There is no guarantee of
              liquidity for the tokenized shares you purchase.
            </li>
            <li>
              <strong>No Endorsement:</strong> Prinx does not endorse or recommend any pre-IPO companies available on
              our platform. We do not provide investment advice or recommendations.
            </li>
            <li>
              <strong>No Guarantees:</strong> Purchasing tokenized shares does not guarantee any positive returns or
              profits.
            </li>
          </ul>
        </section>

        <footer className="mt-10 text-center text-sm text-gray-500">
          If you have any questions, please contact us at <strong>info@prinx.io</strong>.
        </footer>
        <button className="bg-gray-200 p-4" onClick={() => onClose()}>
          {" "}
          Close{" "}
        </button>
      </div>
    </ReactModal>
  );
};

export default TermsOfService;
