import React, { FC, useState } from "react";
import { CompanyDataType } from "../../constants/constants";
interface CustomSelectProps {
  items: CompanyDataType[];
  setSelectedCompany: React.Dispatch<React.SetStateAction<CompanyDataType>>;
  selectedCompany: CompanyDataType;
}

const CustomSelect: FC<CustomSelectProps> = ({ items, setSelectedCompany, selectedCompany }) => {
  const [selectedItem, setSelectedItem] = useState<CompanyDataType>(selectedCompany); // Default to the first item
  const [isOpen, setIsOpen] = useState(false); // Controls dropdown visibility
  const handleSelect = (item: CompanyDataType) => {
    setSelectedItem(item);
    setSelectedCompany(item);
    setIsOpen(false); // Close dropdown after selection
  };
  if (selectedItem) {
    return (
      <div className="relative w-full">
        {/* Displayed selection */}
        <div
          className="flex items-center justify-between bg-white p-3 border rounded-full cursor-pointer"
          onClick={() => setIsOpen(!isOpen)}
        >
          {/* Display selected item's name and image */}
          <div className="flex items-center gap-2">
            <img src={selectedItem.image} alt={selectedItem.title} className="w-6 h-6 rounded-full" />
            <span>{selectedItem.title}</span>
          </div>
          <span className="text-gray-500">▼</span> {/* Down arrow indicator */}
        </div>

        {/* Dropdown list */}
        {isOpen && (
          <div className="absolute top-full left-0 w-full bg-white border rounded shadow-lg mt-2 z-10">
            {items
              .filter((item) => item !== selectedItem) // Exclude the selected item
              .map((item) => (
                <div
                  key={item.title}
                  className="flex items-center gap-2 px-4 py-2 hover:bg-gray-200 cursor-pointer"
                  onClick={() => handleSelect(item)}
                >
                  <img src={item.image} alt={item.title} className="w-6 h-6 rounded-full" />
                  <div>
                    <p className="font-semibold">{item.title}</p>
                    <p className="text-sm text-gray-500">${item.sharePrice.toLocaleString()}</p>
                  </div>
                </div>
              ))}
          </div>
        )}
      </div>
    );
  } else {
    return <div>Loadaing</div>;
  }
};

export default CustomSelect;
