import { FC, useEffect } from "react";

import { ConfigProvider, theme } from "antd";
import { useState } from "react";
import { CustomHeader, MainContent, CustomFooter } from "layout";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { checkGeolocation } from "calls";
import { useWindowSize } from "../../hooks/useWindowSize";
import config from "../../config";
function showPosition(position: any) {
  console.log(`Latitude:  ${position.coords.latitude} Longitude: ${position.coords.longitude}`);
}

const Layout: FC = () => {
  const { defaultAlgorithm, darkAlgorithm } = theme;
  const [isDarkMode, setIsDarkMode] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { isMobile, isTablet } = useWindowSize();
  const currentPath = location.pathname;
  const isAlphaEnv = process.env.REACT_APP_ENV === "ALPHA";
  useEffect(() => {
    if (isAlphaEnv && currentPath !== "/" && currentPath !== "/waitlist") {
      navigate("/waitlist");
    }
    if (currentPath != "/not-here-yet") {
      checkGeolocation().then((data) => {
        if (data.badLocation && config.geofence) {
          navigate("/not-here-yet", { state: data });
        }
      });
    }
  }, [location]);

  return (
    <ConfigProvider
      theme={{
        algorithm: isDarkMode ? darkAlgorithm : defaultAlgorithm
      }}
    >
      {/* Only render CustomHeader if not in ALPHA environment */}
      {!isAlphaEnv && currentPath !== "/" && <CustomHeader isDarkMode={isDarkMode} setIsDarkMode={setIsDarkMode} />}

      <MainContent>
        <div
          style={{
            marginLeft: currentPath === "/" ? 0 : isAlphaEnv || isMobile || isTablet ? 0 : "16rem"
          }} // No margin-left for /landing
          className={`${currentPath === "/" ? "w-full" : isAlphaEnv ? "w-full" : "ml-64 w-full"}`} // Ensure w-full for /landing
        >
          <Outlet />
        </div>
      </MainContent>
    </ConfigProvider>
  );
};

export default Layout;
