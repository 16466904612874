import React, { useContext } from "react";
import { Web3ReactProvider } from "@web3-react/core";
import { createRoot } from "react-dom/client";
import { PrivyProvider } from "@privy-io/react-auth";
import { SmartWalletsProvider } from "@privy-io/react-auth/smart-wallets";
import App from "./App";
import { base } from "viem/chains";
import { BlockChainContextProvider } from "blockchainContext";
import { SharePriceContextProvider } from "sharePriceContext";
import { PrinxContextProvider } from "prinxDataContext";
import "./i18n";
import "./input.css";

const root = createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <PrivyProvider
      appId="cm0yq8l6c03i1gec9i6yz1w6f"
      config={{
        defaultChain: base,
        appearance: {
          accentColor: "#6A6FF5",
          theme: "#FFFFFF",
          showWalletLoginFirst: false,
          logo: "https://pub-dc971f65d0aa41d18c1839f8ab426dcb.r2.dev/privy.png"
        },
        loginMethods: ["email", "google"],
        fundingMethodConfig: {
          moonpay: {
            useSandbox: true,
            paymentMethod: "credit_debit_card",
            uiConfig: { accentColor: "#696FFD", theme: "light" }
          }
        },
        embeddedWallets: {
          createOnLogin: "users-without-wallets",
          requireUserPasswordOnCreate: false
        },
        mfa: {
          noPromptOnMfaRequired: false
        }
      }}
    >
      <PrinxContextProvider>
        <BlockChainContextProvider>
          <SharePriceContextProvider>
            <SmartWalletsProvider>
              <App />
            </SmartWalletsProvider>
          </SharePriceContextProvider>
        </BlockChainContextProvider>
      </PrinxContextProvider>
    </PrivyProvider>
  </React.StrictMode>
);
