import React from "react";
import { PrimaryInvestmentType } from "types";
import { formatToDollar } from "common";
const InvestmentCard: React.FC<{
  investment: PrimaryInvestmentType;
  handleOpenModal?: () => void;
}> = ({ investment, handleOpenModal }) => {
  return (
    <div className="bg-white p-4  rounded-lg">
      <img src={investment.image} alt={investment.title} className="rounded-t-xl mb-4" />
      <h3 className="text-lg font-semibold mb-2">{investment.title}</h3>
      <div className="flex w-full">
        <div className="flex flex-col w-1/2">
          <p className="text-xs"> Est. Price Per Share</p>
          <p className="text-s"> ${investment.sharePrice}</p>
        </div>
        <div className="flex flex-col w-1/2">
          <p className="text-xs"> Class of Share</p>
          <p className="text-xs"> {investment.shareType}</p>
        </div>
      </div>
      <div className="w-full h-3 bg-gray-200 rounded-full mt-4">
        <div
          style={{
            background: "linear-gradient(90deg, #9386F4, #436FE6)",
            width: `${(investment.deposited / (investment.deposited + investment.goal)) * 100}%`
          }}
          className="h-full rounded-full"
        ></div>
      </div>
      <p className="mt-2 mb-2 text-gray-700">
        {investment.deposited == investment.goal
          ? `Fully Allocated`
          : `${formatToDollar(investment.deposited.toString())} Allocated`}
      </p>
      <div className="flex w-full">
        {handleOpenModal ? (
          <button
            style={{ backgroundColor: investment.goal == investment.deposited ? "#D0DBF9" : "#436FE6" }}
            disabled={investment.goal == investment.deposited}
            className="w-full py-2 bg-green-500 text-white font-semibold rounded-lg hover:bg-green-600 transition"
            onClick={handleOpenModal}
          >
            Buy
          </button>
        ) : (
          <button
            style={{ backgroundColor: investment.goal == investment.deposited ? "#D0DBF9" : "#436FE6" }}
            disabled={investment.goal == investment.deposited}
            className="w-full py-2 bg-green-500 text-white font-semibold rounded-lg hover:bg-green-600 transition"
            onClick={() => {
              console.log("Link");
            }}
          >
            Learn More
          </button>
        )}
      </div>
    </div>
  );
};

export default InvestmentCard;
// <ConfirmTransactionModalWrapper action="buy" func={completeTransaction} onClose={() => {}} />;
