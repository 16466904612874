import React, { FC, useState, useEffect } from "react";
import { FaExclamationCircle } from "react-icons/fa";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const UnlinkPhoneModal: FC<ModalProps> = ({ isOpen, onClose, onConfirm }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setIsVisible(true); // Trigger the animation when the modal opens
      return;
    } else {
      // Add a delay to allow the fade-out animation to play before removing from the DOM
      const timeout = setTimeout(() => setIsVisible(false), 300);
      return () => clearTimeout(timeout);
    }
  }, [isOpen]);

  if (!isOpen && !isVisible) return null; // Render nothing when fully hidden

  return (
    <div
      className={`fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 transition-opacity duration-300 ${
        isOpen ? "opacity-100" : "opacity-0"
      }`}
    >
      <div
        className={`bg-white p-6 rounded-lg shadow-lg transform transition-transform duration-300 ${
          isOpen ? "scale-100" : "scale-95"
        }`}
      >
        <div className="flex flex-col items-center">
          <FaExclamationCircle className="text-red-500 text-4xl mb-4" />
          <h2 className="text-lg font-bold text-center mb-4">Are you sure you want to unlink your phone?</h2>
          <p className="text-sm text-gray-600 text-center mb-6">
            If you unlink your phone, you will not be able to trade, and your account will not be recoverable if you get
            locked out of other login methods.
          </p>
          <div className="flex justify-center gap-4">
            <button className="bg-gray-200 text-gray-700 px-4 py-2 rounded-lg hover:bg-gray-300" onClick={onClose}>
              Cancel
            </button>
            <button className="bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600" onClick={onConfirm}>
              Unlink Phone
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnlinkPhoneModal;
