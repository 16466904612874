import { useState } from "react";

const FAQ: React.FC = () => {
  const faqs = [
    {
      question: "How does Prinx's trading work?",
      answer: "It is as simple as logging in, depositing funds, and trading the security when the equity is available."
    },
    {
      question: "Where are my funds stored?",
      answer: "Your funds are securely stored in wallets that were assigned by Privy when you created an account."
    },
    {
      question: "What is USDC?",
      answer: "USDC is a type of stablecoin that is pegged to the value of the U.S. dollar."
    },
    {
      question: "Do I actually own the shares in these companies?",
      answer:
        "Prinx offers securities backed by shares of the companies you are purchasing.  You own the right to claim the stock on the liquidity event, and to trade your interest in the security prior to that event.  You do not have voting rights, or any other rights associated with owning private stock."
    },
    {
      question: "How can I get in contact with Prinx?",
      answer:
        "We love talking to investors! Reach out to Brendan@prinx.io or Patrick@prinx.io."
    }
  ];

  const [openIndex, setOpenIndex] = useState<number | null>(0); // Track which accordion is open

  // Toggle accordion
  const toggleAccordion = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <section className="grid grid-cols-1 md:grid-cols-2 gap-8 p-8 bg-[#0B1225] text-white">
      {/* Left Side */}
      <div className="space-y-4">
        <h1 className="text-4xl font-bold">FAQs</h1>
        <p className="text-lg text-gray-400">
          Havent found what youre looking for? Check out some common questions here, or see our Help Center for more
          info.
        </p>
      </div>

      {/* Right Side: Accordions */}
      <div className="space-y-4">
        {faqs.map((faq, index) => (
          <div key={index} className="border-b border-gray-600 pb-4">
            <button
              onClick={() => toggleAccordion(index)}
              className="flex justify-between items-center w-full text-left focus:outline-none text-lg font-medium"
            >
              <span>{faq.question}</span>
              <span className="text-blue-400 text-2xl">{openIndex === index ? "-" : "+"}</span>
            </button>
            {openIndex === index && <p className="mt-4 text-white text-base">{faq.answer}</p>}
          </div>
        ))}
      </div>
    </section>
  );
};

export default FAQ;
