import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";

interface DataPoint {
  date: Date;
  price: number;
}

const StockChart: React.FC<{ data: DataPoint[] }> = ({ data }) => {
  const svgRef = useRef<SVGSVGElement | null>(null);
  const [dimensions, setDimensions] = useState({ width: 600, height: 300 });

  useEffect(() => {
    const updateDimensions = () => {
      const width = svgRef.current ? svgRef.current.clientWidth : 600;
      const height = 300;
      setDimensions({ width, height });
    };

    updateDimensions();
    window.addEventListener("resize", updateDimensions);

    return () => {
      window.removeEventListener("resize", updateDimensions);
    };
  }, []);

  useEffect(() => {
    if (!data.length) return;

    const svg = d3.select(svgRef.current);
    svg.selectAll("*").remove(); // Clear previous drawings

    const margin = { top: 0, right: 0, bottom: 0, left: 0 };
    const width = dimensions.width - margin.left - margin.right;
    const height = dimensions.height - margin.top - margin.bottom;

    // Scales
    const x = d3
      .scaleTime()
      .domain(d3.extent(data, (d) => d.date) as [Date, Date])
      .range([0, width]);

    const y = d3
      .scaleLinear()
      .domain([0, d3.max(data, (d) => d.price) || 0])
      .range([height, 0]);

    // Line and Area generators
    const line = d3
      .line<DataPoint>()
      .x((d) => x(d.date) as number)
      .y((d) => y(d.price) as number);

    const area = d3
      .area<DataPoint>()
      .x((d) => x(d.date) as number)
      .y0(height)
      .y1((d) => y(d.price) as number);

    // Append a pattern and gradient definition in the <defs> section
    const defs = svg.append("defs");

    // Purple gradient for the area fill
    const gradient = defs
      .append("linearGradient")
      .attr("id", "areaGradient")
      .attr("x1", "0%")
      .attr("y1", "0%")
      .attr("x2", "0%")
      .attr("y2", "100%");

    // Define the color stops for the gradient
    gradient.append("stop").attr("offset", "0%").attr("stop-color", "rgba(147, 134, 244, 0.6)"); // Light purple with partial opacity

    gradient.append("stop").attr("offset", "100%").attr("stop-color", "#F5F7FD"); // Bottom color

    // Dotted pattern for the background
    const dotSize = 2; // Size of each dot
    const dotSpacing = 20; // Space between dots

    defs
      .append("pattern")
      .attr("id", "dotPattern")
      .attr("width", dotSpacing)
      .attr("height", dotSpacing)
      .attr("patternUnits", "userSpaceOnUse")
      .append("circle")
      .attr("cx", dotSize / 2)
      .attr("cy", dotSize / 2)
      .attr("r", dotSize / 2)
      .attr("fill", "lightgray"); // Color of the dots

    // Main group for chart elements
    const g = svg.append("g").attr("transform", `translate(${margin.left},${margin.top})`);

    // Add the dotted background
    g.append("rect").attr("width", width).attr("height", height).attr("fill", "url(#dotPattern)");

    // Add the shaded area with gradient
    g.append("path")
      .datum(data)
      .attr("fill", "url(#areaGradient)") // Apply the gradient fill
      .attr("d", area);

    // Add the line
    g.append("path")
      .datum(data)
      .attr("fill", "none")
      .attr("stroke", "#9386F4") // Purple line to match the gradient
      .attr("stroke-width", 1.5)
      .attr("d", line);

    // Add axes
    g.append("g").attr("class", "x-axis").attr("transform", `translate(0,${height})`).call(d3.axisBottom(x));
    g.append("g").attr("class", "y-axis").call(d3.axisLeft(y));
    g.select(".x-axis path").attr("stroke", "none"); // Remove x-axis line
    g.select(".y-axis path").attr("stroke", "none"); // Remove x-axis line
  }, [data, dimensions]);

  return (
    <div className="stock-chart">
      <div className="time-select mt-2 inline-flex flex-row ml-4 rounded">
        <div className="bg-white child p-1 m-1">1W</div>
        <div className="child p-1 m-1">1M</div>
        <div className="child p-1 m-1">3M</div>
        <div className="child p-1 m-1">YTD</div>
        <div className="child p-1 m-1">1Y</div>
        <div className="child p-1 m-1">5Y</div>
      </div>
      <svg
        ref={svgRef}
        width="100%"
        height={dimensions.height} // Ensure a specific height is set here
        viewBox={`0 0 ${dimensions.width} ${dimensions.height}`}
      />
    </div>
  );
};

export default StockChart;
