import { SPACE_X_BIO } from "./bios/space-x";
import { UNITY_BIO } from "./bios/unity-bio";

import { PrimaryInvestmentType } from "types";
import spacexLogo from "../assets/images/space-x.png";

import CanvaLogo from "../assets/images/canva.png";

export interface CompanyDataType {
  abi: object;
  address: string;
  bio: string;
  image: string;
  sharePrice: number;
  sellSharePrice: number;
  shareType: string;
  title: string;
  ticker: string;
  token: any; // Adjust this type based on your actual Token type
  volumeTraded: number;
  uri: string;
  tradePool: string;
}

export const PrimaryInvestments: PrimaryInvestmentType[] = [
  {
    title: "Space X",
    shareType: "Class A Common Shares",
    sharePrice: 113,
    deposited: 0,
    goal: 1,
    image: spacexLogo,
    wallet_address: "0xbdae9e5d300a46828796ffe8f8516c26cd8d7ad1",
    uri: "/primary/space-x",
    bio: SPACE_X_BIO(),
    userDeposit: 0
  },
  {
    title: "Canva",
    shareType: "Class B Common Shares",
    sharePrice: 27,
    deposited: 0,
    goal: 1,
    image: CanvaLogo,
    wallet_address: "0x3f1B244b1b8FC25Ed44A20355FDa2f9e51010c91",
    uri: "/primary/unity",
    bio: UNITY_BIO(),
    userDeposit: 0
  }
];
