// USDC address
export const USDC_ADDRESS = "0x009764F6D17337B6a0033d95A30bDc25896075b4";
// tokens addresses
export const SPACEX_ADDRESS = "0x626fF50B8d6Bd2cb16178621F3FbE2416b9A3147";
export const DATA_BRICKS_ADDRESS = "0x1fE2f3a8BbAdFF8b73A1229c63204CDC709c2eB8";
export const UNITY_ADDRESS = "0x0f6B2AadEe012EE3Cc71091f224b6eA614602b57";
// deposit contracts
export const SpaceXDepositContract = "0xCD1fCe8679588A7Fc7BdcE72bcAb47563517C85b";
export const EpicGamesDepositContract = "0x280756cc4B3fb390A9DAE0C1741cD1e36BCc94B3";
export const UnityDepositContract = "0x81f784D435D252D7CDC6B3B95518c1d16D349De5";

export const SpaceXTradePool = "0x7E062013BBB6e3D18F2735a761E102CFFd220eF7";
export const DataBricksTradePool = "0x685da715BC81A77705b7Eb61171003C8e1E8160f";
export const UnityTradePool = "0x6fE144104696C5D7D3b9c99EB6937d022d0e995B";
