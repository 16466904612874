import spinner from "../../assets/images/loading-gif.gif";

interface TradeWidgetLoaderProps {
  loading: boolean;
}

const TradeWidgetLoader: React.FC<TradeWidgetLoaderProps> = ({ loading }) => {
  if (loading) {
    return (
      <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <img src={spinner} alt="Loading..." className="w-12 h-12" />
      </div>
    );
  } else {
    return null;
  }
};

export default TradeWidgetLoader;
