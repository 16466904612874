const DEV_ROOT = "http://localhost:3002/";
const PROD_ROOT = "https://polar-reef-74128-7c129d9dd252.herokuapp.com/";
const ROOT = process.env.NODE_ENV == "production" ? PROD_ROOT : DEV_ROOT;

export async function writeUser(token: any) {
  fetch(`${ROOT}session`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json" // Specify the content type
    },
    body: JSON.stringify(token) // Convert the data to a JSON string
  })
    .then((response) => response.json())
    .then((json) => console.log(json))
    .catch((error) => console.error(error));
}

export async function updateUser(user: any) {
  fetch(`${ROOT}user/${user.id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json" // Specify the content type
    },
    body: JSON.stringify(user) // Convert the data to a JSON string
  })
    .then((response) => response.json())
    .then((json) => console.log(json))
    .catch((error) => console.error(error));
}

export async function recordTransaction(data: object): Promise<void> {
  console.log("Data Call", data);
  return fetch(`${ROOT}transactions/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json" // Specify the content type
    },
    body: JSON.stringify(data) // Convert the data to a JSON string
  })
    .then((response) => response.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((error) => console.error(error));
}

export async function checkGeolocation() {
  return fetch(`${ROOT}geolocation`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json" // Specify the content type
    }
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((error) => console.error(error));
}

export async function joinWaitlist(email: string, selectedCompany: string) {
  return fetch(`${ROOT}join-waitlist`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json" // Specify the content type
    },
    body: JSON.stringify({ email, selectedCompany })
  })
    .then((response) => {
      if (!response.ok) {
        // If the response is not ok (e.g., status is 500), throw an error
        return response.json().then((json) => {
          throw new Error(json.message || "Server error");
        });
      }
      console.log("Test", response);
      return response.json(); // Otherwise, parse and return the JSON
    })
    .catch((error) => {
      console.error("Fetch error:", error);
      throw error; // Re-throw the error to be caught by the caller
    });
}

export async function getPortfolioData(smart_wallet: string) {
  return fetch(`${ROOT}portfolio/${smart_wallet}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json" // Specify the content type
    }
  })
    .then((response) => response.json())
    .then((json) => {
      const transformedHoldings = json.holdings.map((holding: any) => {
        // Calculate the sum of the `value` field in the items array
        const totalValue = holding.items.reduce((sum: number, item: any) => sum + item.value, 0);

        // Return a new object with `date` and the `value` sum
        return {
          date: new Date(holding.date), // Keep the original date
          price: totalValue // Summed value
        };
      });
      return transformedHoldings;
    })
    .catch((error) => console.error(error));
}

export const fetchAssets = async () => {
  try {
    const response = await fetch(`${ROOT}assets`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      }
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const assets = await response.json();
    return assets; // Return the data if needed
  } catch (error) {
    console.error("Error fetching assets:", error);
  }
};
