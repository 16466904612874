import React, { useEffect } from "react";
import { joinWaitlist } from "calls";
import mixpanel from "mixpanel-browser";
import spinner from "../../assets/spinner2.gif";
import { useTranslation } from "react-i18next";
import LanguageSelect from "components/LanguageSelect/LanguageSelect";
import ToSModal from "../Modals/ToS";
import PPModal from "../Modals/PP";
declare global {
  const twq: (command: string, ...args: any[]) => void;
}
interface WaitlistType {
  str: string;
  ab: string; // assuming "ab" only accepts "a" or "b"
}
const emailPattern = /^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/;
const styles: {
  background: React.CSSProperties;
  overlay: React.CSSProperties;
} = {
  background: {
    backgroundImage: "linear-gradient(to bottom right, #131C34 50%, #8D80F4 100%)",
   // backgroundImage: window.innerWidth > 480 ? `url(${BackgroundImage})` : `url(${MobileBackgroundImage})`,
    backgroundSize: "cover",
    height: "100vh",
    width: "100vw"
  },
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100vh",
    backgroundColor: "rgba(0, 0, 0, 0.6)", // Black with 50% opacity
    zIndex: 1
  }
};
const Waitlist: React.FC<WaitlistType> = ({ str, ab }) => {
  // Sample investment data
  const [inputValue, setInputValue] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [successMessage, setSuccessMessage] = React.useState("");
  const [selectedCompany, setSelectedCompany] = React.useState<string>("");
  const { t, i18n } = useTranslation();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [modalContent, setModalContent] = React.useState<string | null>(null);

  useEffect(() => {
    console.log("Logging LandingPageView");
    mixpanel.track("LandingPageView", {
      ab: ab
    });
  }, []);

  const openModal = (content: string) => {
    setModalContent(content);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalContent(null);
  };
  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedCompany(event.target.value);
  };

  const handleClick = async (inputValue: string, ab: string) => {
    setLoading(true);
    setErrorMessage(""); // Clear any previous error message

    // Validate email
    if (!emailPattern.test(inputValue)) {
      setErrorMessage("Please enter a valid email address.");
      setLoading(false);
      return;
    }
    if (!selectedCompany) {
      setErrorMessage("C'mon!  Choose a company from the bar above the email field");
      setLoading(false);
      return;
    }

    try {
      const res = await joinWaitlist(inputValue, selectedCompany); // Wait for the async call to complete
      mixpanel.track("Success-Signup", {
        email: res.data.email,
        ab: ab,
        company: selectedCompany
      });
      twq("event", "tw-signup", {
        conversion_id: null // use this to pass a unique ID for the conversion event for deduplication (e.g. order id '1a2b3c')
      });
      setLoading(false);
      setInputValue("");
      setSelectedCompany("");
      setSuccessMessage("Thank You! We will be in touch. Check your email");
    } catch (err) {
      console.error(err);
      mixpanel.track("Failed-Signup", {
        Error: err
      });
      twq("event", "tw-fail-signup", {
        conversion_id: null // use this to pass a unique ID for the conversion event for deduplication (e.g. order id '1a2b3c')
      });
      setLoading(false);
      setInputValue("");
      setSuccessMessage("");
      setSelectedCompany("");
      setErrorMessage("Something went wrong. Please try again."); // Display an error message
    }
  };
  const renderModalContent = () => {
    switch (modalContent) {
      case "tos":
        return <ToSModal isAnimating={true} onClose={closeModal} />;
      case "pp":
        return <PPModal isAnimating={true} onClose={closeModal} />;
      default:
        return null;
    }
  };
  return (
    <div
      style={styles.background}
      className="h-screen flex items-center justify-center bg-gradient-to-br from-purple-500 to-blue-600 text-white"
    >
      <div style={{ zIndex: 2, width: "80%", margin: "0 auto" }}>
        <div style={{ position: "absolute", top: 10, right: 10 }}>
          <LanguageSelect />
        </div>

        <div className="text-center px-6">

        <h1 className="text-6xl md:text-7xl font-bold mb-4
          bg-gradient-to-r from-[#D7FC70] via-[#83D2F7] to-[#436FE7] bg-clip-text text-transparent"
          style={{ lineHeight: "1.2" }} 
          >
            {t("waitlist.coming_2025")}
          </h1>
          <p className="text-lg md:text-xl mb-6">{t(`waitlist.${str}`)}</p>
          <div style={{ height: "50px" }}></div>  
          <p className="text-sm md:text-sm mb-6">{t("waitlist.otherText")}</p>
          <div className="flex flex-col items-center">
            <label htmlFor="investment-select">{t("waitlist.investment_options")}</label>
            <select
              className="rounded text-black"
              id="investment-select"
              value={selectedCompany}
              onChange={handleSelectChange}
              style={{ 
                height: 40, 
                margin: "10px 0", // Add vertical margin for spacing
                backgroundColor: "transparent", 
                color: "white", 
                border: "1px solid white", 
                padding: "10px", 
                fontWeight: "bold", 
                borderRadius: "5px", 
                cursor: "pointer" 
              }}
            >
              <option value="" disabled>
                {t("waitlist.invest_in")}
              </option>
              <option value="Epic Games">Epic Games</option>
              <option value="Niantic">Niantic</option>
              <option value="Discord">Discord</option>
              <option value="Canva">Canva</option>
              <option value="Kraken">Kraken</option>
            </select>

            {selectedCompany && (
              <p style={{ marginTop: "10px" }}>
                You have selected: <strong>{selectedCompany}</strong>
              </p>
            )}

            <input
              style={{
                marginTop: "10px",
                backgroundColor: "transparent",
                border: "1px solid white",
                color: "white",
                padding: "10px",
                borderRadius: "5px",
                outline: "none",
                fontWeight: "bold" // Make the text bold
              }}
              value={inputValue}
              placeholder={t("waitlist.email")}
              onChange={(e) => {
                setInputValue(e.target.value);
              }}
              className="mb-4 h-[40px]"
            />

            <button
              onClick={(e) => {
                handleClick(inputValue, ab);
              }}
              style={{ backgroundColor: "#436FE6", color: "white", fontWeight: "bold", padding: "10px 20px", borderRadius: "5px", cursor: "pointer", marginTop: "10px" }}
              className="mx-2 shadow-md hover:bg-gray-100 transition"
            >
              {t("waitlist.early_access")}
            </button>
          </div>
          <div style={{ textAlign: "center" }}>
            {loading ? <img style={{ margin: "0 auto", width: 50, height: 50 }} src={spinner} /> : null}
            <div>
              {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
              {successMessage && <p style={{ color: "white" }}>{successMessage}</p>}
              {/* Rest of your component */}
            </div>
          </div>
        </div>
        <div className="text-center mt-10">
          <p className="text-sm underline cursor-pointer" onClick={() => openModal("tos")}>
            {t("tos")}
          </p>
          <p className="text-sm underline cursor-pointer" onClick={() => openModal("pp")}>
            {t("pp")}
          </p>
          {/* Modal Component */}
          {isModalOpen && <div style={{ zIndex: 9 }}>{renderModalContent()}</div>}
        </div>
      </div>
    </div>
  );
};

export default Waitlist;
