import React, { useContext } from "react";
import InvestmentList from "./InvestmentList";
import { PrimaryInvestments } from "../../constants/constants";
import SharePriceContext from "sharePriceContext";

const Home: React.FC = () => {
  const { companyData, primaryCompanyData } = useContext(SharePriceContext);

  return (
    <div style={{ backgroundColor: "f6f7fc" }} className="min-h-screen p-6">
      {/* <SetupStepsBar /> Add the SetupStepsBar component */}
      <h1 className="mb-4"> Primary Market </h1>
      <InvestmentList investments={primaryCompanyData} />
    </div>
  );
};

export default Home;
