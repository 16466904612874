import EpicGames from "../../assets/images/epic-games-white.png";
import Discord from "../../assets/images/discord-white.png";
import Flexport from "../../assets/images/flexport-white.png";
import Circle from "../../assets/images/data-bricks-white.png";
import React, { useRef, useEffect, useState } from "react";

const Slider: React.FC = () => {
  const images = [
    { src: Circle, alt: "Circle", width: 80 },
    { src: Discord, alt: "Discord", width: 80 },
    { src: EpicGames, alt: "Epic Games", width: 40 },
    { src: Flexport, alt: "Flexport", width: 80 },
    { src: Circle, alt: "Circle", width: 80 },
    { src: Discord, alt: "Discord", width: 80 },
    { src: EpicGames, alt: "Epic Games", width: 40 },
    { src: Flexport, alt: "Flexport", width: 80 }
  ];
  const sliderRef = useRef<HTMLDivElement>(null);
  const [offset, setOffset] = useState(0);
  const [totalWidth, setTotalWidth] = useState(0);
  const speed = 0.5; // Speed in pixels per frame

  useEffect(() => {
    const slider = sliderRef.current;
    if (!slider) return;

    // Calculate total width of all children manually
    const total = Array.from(slider.children).reduce((acc, child) => {
      const childWidth = (child as HTMLElement).offsetWidth;
      const gap = parseInt(getComputedStyle(slider).gap || "0", 10); // Account for gaps
      return acc + childWidth + gap;
    }, 0);

    setTotalWidth(total);

    let animationFrame: number;

    const step = () => {
      setOffset((prevOffset) => {
        const newOffset = prevOffset + speed;

        // Reset offset when it reaches the total width of the visible images
        if (newOffset >= total / 2) {
          console.log("Reset");
          return 0; // Reset to avoid snapping
        }
        return newOffset;
      });

      animationFrame = requestAnimationFrame(step);
    };

    animationFrame = requestAnimationFrame(step);

    return () => cancelAnimationFrame(animationFrame);
  }, [speed]);

  return (
    <div className="relative overflow-hidden" style={{ width: "100%" }}>
      <div
        ref={sliderRef}
        className="flex gap-4"
        style={{
          transform: `translateX(-${offset}px)`,
          whiteSpace: "nowrap"
        }}
      >
        {/* Duplicate images for seamless loop */}
        {images.concat(images).map((image, index) => (
          <img
            key={index}
            src={image.src}
            alt={image.alt}
            style={{ width: `${image.width}px` }}
            className="rounded-lg"
          />
        ))}
      </div>
    </div>
  );
};

export default Slider;
