import Masthead from "./Masthead";
import HowItWorks from "./HowItWorks";
import EffortlesslyTrade from "./EffortlesslyTrade";
import WhyInvest from "./WhyInvest";
import DidYouKnow from "./DidYouKnow";
import FAQ from "./FAQ";
const LandingPage: React.FC = () => {
  return (
    <div style={{ backgroundColor: "#131C34" }} className="text-white font-sans">
      {/* Hero Section */}
      <Masthead />
      <HowItWorks />
      <EffortlesslyTrade />
      <WhyInvest />
      <DidYouKnow />
      <FAQ />
    </div>
  );
};

export default LandingPage;
