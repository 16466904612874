interface TradeWidgetInputProps {
  inputValue: string;
  denomination: string;
  setInputValue: any;
}
const TradeWidgetInput: React.FC<TradeWidgetInputProps> = ({ inputValue, denomination, setInputValue }) => {
  return (
    <>
      <input
        type="number"
        min={denomination === "dollars" ? "0.01" : "0.000001"}
        step={denomination === "dollars" ? "0.01" : "0.000001"}
        style={{ height: 50 }}
        value={inputValue}
        onChange={(e) => {
          const value = e.target.value;
          if (denomination === "dollars") {
            // Allow empty input, decimal point, and valid numbers for dollars
            if (value === "" || value === "." || value === "0." || /^\d*\.?\d{0,2}$/.test(value)) {
              setInputValue(value);
            }
          } else {
            // For shares, allow up to 6 decimal places
            if (value === "" || value === "." || value === "0." || /^\d*\.?\d{0,6}$/.test(value)) {
              setInputValue(value);
            }
          }
        }}
        onBlur={() => {
          if (denomination === "dollars") {
            // Format dollars to 2 decimal places
            if (inputValue === "" || inputValue === "." || inputValue === "0.") {
              setInputValue("");
            } else if (parseFloat(inputValue) < 0.01) {
              setInputValue("");
            } else {
              setInputValue(parseFloat(inputValue).toFixed(2));
            }
          } else {
            // For shares, format to 6 decimal places
            if (inputValue === "" || inputValue === "." || inputValue === "0.") {
              setInputValue("");
            } else if (parseFloat(inputValue) < 0.000001) {
              setInputValue("");
            } else {
              setInputValue(parseFloat(inputValue).toFixed(6));
            }
          }
        }}
        className="w-1/2"
      />
    </>
  );
};

export default TradeWidgetInput;
