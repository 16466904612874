import { formatToDollar } from "common";

interface UserBalancesProps {
  action: string;
  usdcBalance: any;
  foundBalance: any;
}

const UserBalances: React.FC<UserBalancesProps> = ({ action, usdcBalance, foundBalance }) => {
  if (action === "buy") {
    return (
      <div className="mt-2 text-left text-xs">
        Cash Balance: {formatToDollar(Number(usdcBalance).toFixed(2))}
        {/* <div className="mt-2">Available Shares: {poolBalance}</div> */}
      </div>
    );
  } else {
    return <div className="text-left text-xs">Current Balance: {foundBalance} shares</div>;
  }
};

export default UserBalances;
