import React from "react";
import Modal from "react-modal";
import spinner from "../../assets/spinner.gif";
const customStyles: ReactModal.Styles = {
  content: {
    position: "fixed" as const,
    top: "auto",
    left: "0",
    right: "0",
    bottom: "0",
    height: "40vh",
    borderTopLeftRadius: "1rem",
    borderTopRightRadius: "1rem",
    margin: "0",
    padding: "1rem",
    transform: "translateY(100%)", // Start off-screen
    transition: "transform 0.25s ease, opacity 0.25s ease", // Animate up and fade-in
    opacity: 0 // Start hidden
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    transition: "opacity 0.25s ease", // Fade-in overlay
    opacity: 0
  }
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#root");

interface ConfirmTransactionModalProps {
  action: "buy" | "sell";
  func: () => Promise<void>;
  isAnimating: boolean;
  onClose: () => void;
  investment: any;
  inputValue: number;
}

const ConfirmTransactionModalWrapper: React.FC<ConfirmTransactionModalProps> = ({
  action,
  func,
  onClose,
  isAnimating,
  investment,
  inputValue
}) => {
  const [isLoading, setIsLoading] = React.useState(false);

  console.log("action?", inputValue);

  return (
    <div className="w-1/2 py-2 bg-green-500 text-white font-semibold rounded-r-lg hover:bg-green-600 transition">
      <Modal
        isOpen={true}
        onRequestClose={onClose}
        style={{
          ...customStyles,
          content: {
            ...customStyles.content,
            transform: isAnimating ? "translateY(0)" : "translateY(100%)",
            opacity: isAnimating ? 1 : 0
          },
          overlay: {
            ...customStyles.overlay,
            opacity: isAnimating ? 1 : 0
          }
        }}
        contentLabel="Example Modal"
      >
        <div className="bg-white rounded-lg p-6 w-full h-full flex flex-col justify-center items-center">
          <h2 className="text-2xl font-semibold text-gray-800 mb-4 text-center w-full">{investment.name}</h2>
          <p className="text-gray-600 text-lg mb-6 text-center w-full">
            Buy <span className="font-bold text-gray-800">${inputValue}</span> Worth of {investment.name} Shares Send
          </p>
          <div style={{ background: "black", height: 1, width: "120%" }}></div>
          <div style={{ fontSize: 18, marginTop: 20 }}>To: </div>
          <span style={{ fontSize: 12, marginBottom: 20 }}>{investment.wallet_address}</span>
          <button
            onClick={() => {
              setIsLoading(true);
              func();
            }}
            className="w-full py-3 bg-blue-500 text-white font-semibold rounded-lg hover:bg-blue-600 transition-colors duration-300"
          >
            Confirm
          </button>
          {isLoading ? (
            <div>
              <img src={spinner} width="100px" />
            </div>
          ) : null}
        </div>
      </Modal>
    </div>
  );
};

export default ConfirmTransactionModalWrapper;
