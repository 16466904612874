import React, { createContext, useEffect, useState } from "react";
import { fetchAssets } from "calls";

interface PrinxContextType {
  //here is where we type all the state values and the functions
  assetData: [] | null;
}

const PrinxContext = createContext<PrinxContextType>({
  assetData: []
});

export const PrinxContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [assets, setAssets] = useState<any>(null);

  useEffect(() => {
    const getAssets = async () => {
      const assets = await fetchAssets();
      console.log("Assets?", assets);
      setAssets(assets);
    };
    if (!assets) getAssets();
  }, [assets]);

  const value = {
    assetData: assets
  };

  return <PrinxContext.Provider value={value}>{children}</PrinxContext.Provider>;
};

export default PrinxContext;
