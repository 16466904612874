import { FC } from "react";
import { usePrivy } from "@privy-io/react-auth";
import { FaIdCard, FaWallet, FaPhone } from "react-icons/fa";

const styles = {
  wrap: {
    borderRadius: "8px",
    position: "relative"
  },
  group: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "10px"
  },
  step: {
    backgroundColor: "white",
    flex: 1,
    padding: "10px",
    borderRadius: "8px",
    textAlign: "center",
    boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "14px",
    gap: "5px"
  },
  icon: {
    fontSize: "20px",
    color: "#007bff"
  }
} as const;

const determineRemaingingSteps = (user: any, usdcBalance: string) => {
  const phone = user?.linkedAccounts.find((account: any) => account.type === "phone");
  const stepsRemaning = [];
  if (!phone) stepsRemaning.push("phone");
  if (parseInt(usdcBalance) == 0) stepsRemaning.push("fund");
  //for now just add kyc and fund
  stepsRemaning.push("kyc");
  return stepsRemaning;
};
interface BalanceSummaryProps {
  usdcBalance: string;
}

const UserSteps: FC<BalanceSummaryProps> = ({ usdcBalance }) => {
  const { user, linkPhone } = usePrivy();
  const steps = determineRemaingingSteps(user, usdcBalance);
  return (
    <div style={styles.wrap} className="mb-2">
      <h4 style={{ marginBottom: "10px", position: "absolute", top: "-32px" }}>Finish Setting Up Your Account</h4>

      <div style={styles.group}>
        {/* Step 1: Sign Up */}
        {steps.includes("phone") ? (
          <div style={styles.step} onClick={linkPhone} className="cursor-pointer">
            <FaPhone style={styles.icon} />
            <div>Link Phone</div>
            <div style={{ fontSize: "12px", marginTop: "5px" }}>For Account Recovery</div>
          </div>
        ) : null}

        {/* Step 2: Verify Identity */}
        <div style={styles.step}>
          <FaIdCard style={styles.icon} />
          <div>Verify Identity</div>
          <div style={{ fontSize: "12px", marginTop: "5px" }}>Complete ID Verification</div>
        </div>

        {/* Step 3: Fund Account */}
        {steps.includes("fund") ? (
          <div style={styles.step}>
            <FaWallet style={styles.icon} />
            <div>Fund Account</div>
            <div style={{ fontSize: "12px", marginTop: "5px" }}>Add funds to your account</div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default UserSteps;
