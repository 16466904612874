export const formatToDollar = (amountString: string): string => {
  // Convert the string to a number
  const amount = parseFloat(amountString);

  // Check if the parsed number is valid
  if (isNaN(amount)) {
    return "Invalid amount";
  }

  // Format the number directly to include commas and two decimals
  return amount.toLocaleString("en-US", { style: "currency", currency: "USD" });
};
