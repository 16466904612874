import React, { FC, useState, useEffect, useRef } from "react";
import { FaLock } from "react-icons/fa";

interface DigitInputProps {
  onChange: (value: string) => void; // Callback to send the value to the parent
}

const DigitInput: React.FC<DigitInputProps> = ({ onChange }) => {
  const [values, setValues] = useState(["", "", "", "", "", ""]);

  // Explicitly type the ref as an array of HTMLInputElement or null
  const inputsRef = useRef<(HTMLInputElement | null)[]>([]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const newValues = [...values];
    newValues[index] = e.target.value.slice(0, 1); // Only allow one character
    setValues(newValues);

    onChange(newValues.join(""));

    // Move to the next input if a value is entered
    if (e.target.value && index < 5) {
      inputsRef.current[index + 1]?.focus();
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (e.key === "Backspace" && !values[index] && index > 0) {
      inputsRef.current[index - 1]?.focus();
    }
  };

  return (
    <div className="flex space-x-2">
      {values.map((value, index) => (
        <input
          key={index}
          type="text"
          inputMode="numeric"
          maxLength={1}
          value={value}
          onChange={(e) => handleChange(e, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          ref={(el) => (inputsRef.current[index] = el)}
          className="w-12 h-12 text-center text-xl font-bold border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      ))}
    </div>
  );
};

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  qrCode: string;
  setCode: any;
}

const QRCodeModal: FC<ModalProps> = ({ isOpen, onClose, onConfirm, qrCode, setCode }) => {
  const [isVisible, setIsVisible] = useState(false);
  const modalRef = useRef<HTMLDivElement | null>(null);

  const handleCodeChange = (value: string) => {
    setCode(value);
    console.log("Updated Code:", value);
  };

  const handleSubmit = () => {
    onConfirm();
  };

  const handleOutsideClick = (e: MouseEvent) => {
    if (modalRef.current && !modalRef.current.contains(e.target as Node)) {
      onClose();
    }
  };

  const handleEscKey = (e: KeyboardEvent) => {
    if (e.key === "Escape") {
      onClose();
    }
  };

  useEffect(() => {
    if (isOpen) {
      setIsVisible(true);
      document.addEventListener("mousedown", handleOutsideClick);
      document.addEventListener("keydown", handleEscKey);
    } else {
      const timeout = setTimeout(() => setIsVisible(false), 300);
      document.removeEventListener("mousedown", handleOutsideClick);
      document.removeEventListener("keydown", handleEscKey);
      return () => clearTimeout(timeout);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
      document.removeEventListener("keydown", handleEscKey);
    };
  }, [isOpen]);

  if (!isOpen && !isVisible) return null;

  return (
    <div
      className={`fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 transition-opacity duration-300 ${
        isOpen ? "opacity-100" : "opacity-0"
      }`}
    >
      <div
        ref={modalRef}
        className={`bg-white p-6 rounded-lg shadow-lg transform transition-transform duration-300 ${
          isOpen ? "scale-100" : "scale-95"
        }`}
      >
        <div className="flex flex-col items-center">
          <FaLock className="text-blue-500 text-4xl mb-4" />
          <h2 className="text-lg font-bold text-center mb-4">Scan QR Code to Add Prinx To Your Authenticator</h2>
          <p className="text-sm text-gray-600 text-center mb-6">
            You will be prompted for codes when making transactions.
          </p>
          <img src={qrCode} alt="Generated QR Code" className="w-48 h-48" />
          <DigitInput onChange={handleCodeChange} />
          <div className="flex justify-center gap-4 mt-4">
            <button className="bg-gray-200 text-gray-700 px-4 py-2 rounded-lg hover:bg-gray-300" onClick={handleSubmit}>
              Submit
            </button>
            <button className="bg-gray-200 text-gray-700 px-4 py-2 rounded-lg hover:bg-gray-300" onClick={onClose}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QRCodeModal;
