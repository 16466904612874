import React, { useContext, useEffect, useRef, useState } from "react";
import * as d3 from "d3";
interface DataPoint {
  date: Date;
  price: number;
}

const PortfolioChart: React.FC<{ data: DataPoint[] }> = ({ data }) => {
  const svgRef = useRef<SVGSVGElement | null>(null);

  const [dimensions, setDimensions] = useState({ width: 400, height: 200 });

  useEffect(() => {
    const updateDimensions = () => {
      const width = svgRef.current ? svgRef.current.clientWidth : 400;
      const height = 200;
      setDimensions({ width, height });
    };

    updateDimensions(); // Set initial dimensions
    window.addEventListener("resize", updateDimensions);

    return () => {
      window.removeEventListener("resize", updateDimensions);
    };
  }, []);

  useEffect(() => {
    if (!data.length) return;

    const svg = d3.select(svgRef.current);
    svg.selectAll("*").remove(); // Clear previous drawings

    const width = dimensions.width;
    const height = dimensions.height;

    // Parse and validate data
    const validatedData = data
      .map((d) => ({
        date: new Date(d.date),
        price: +d.price
      }))
      .filter((d) => !isNaN(d.price) && d.date instanceof Date);

    // Scales
    const x = d3
      .scaleTime()
      .domain(d3.extent(validatedData, (d) => d.date) as [Date, Date])
      .range([0, width]);

    const priceExtent = d3.extent(validatedData, (d) => d.price) as [number, number];
    const padding = (priceExtent[1] - priceExtent[0]) * 0.1;

    const y = d3
      .scaleLinear()
      .domain([priceExtent[0] - padding, priceExtent[1] + padding]) // Add padding
      .range([height, 0]);

    // Line and area generators
    const line = d3
      .line<DataPoint>()
      .x((d) => x(d.date) as number)
      .y((d) => y(d.price) as number);

    const area = d3
      .area<DataPoint>()
      .x((d) => x(d.date) as number)
      .y0(height)
      .y1((d) => y(d.price) as number);

    // Append defs for gradient and patterns
    const defs = svg.append("defs");

    const gradient = defs
      .append("linearGradient")
      .attr("id", "areaGradient")
      .attr("x1", "0%")
      .attr("y1", "0%")
      .attr("x2", "0%")
      .attr("y2", "100%");

    gradient.append("stop").attr("offset", "0%").attr("stop-color", "rgba(147, 134, 244, 0.6)");
    gradient.append("stop").attr("offset", "100%").attr("stop-color", "#F5F7FD");

    // Main group for chart elements
    const g = svg.append("g");

    // Add the shaded area with gradient
    g.append("path").datum(validatedData).attr("fill", "url(#areaGradient)").attr("d", area);

    // Add the line
    g.append("path")
      .datum(validatedData)
      .attr("fill", "none")
      .attr("stroke", "#9386F4")
      .attr("stroke-width", 2)
      .attr("d", line);

    // Add the y-axis absolutely
    const yAxis = d3.axisLeft(y).ticks(5); // Add ticks to the y-axis

    const yAxisGroup = svg.append("g").call(yAxis).attr("class", "y-axis-overlay");

    // Move tick marks and labels inside the chart area
    yAxisGroup
      .selectAll("text")
      .attr("x", 8) // Move labels to the right
      .attr("y", 10) // Move labels to the right
      .attr("text-anchor", "start") // Align labels to the left
      .attr("fill", "black"); // Optional: Set text color for better visibility

    yAxisGroup
      .selectAll("line")
      .attr("x1", 0) // Offset tick lines to start inside the chart
      .attr("x2", "100%") // Lengthen tick marks to the right
      .attr("stroke", "gray") // Optional: Style tick marks
      .attr("stroke-dasharray", "1 8"); // Dashed pattern: 4px dash, 4px gap
  }, [data, dimensions]);

  return (
    <div className="flex">
      <svg
        ref={svgRef}
        width="100%"
        height={dimensions.height}
        viewBox={`0 0 ${dimensions.width} ${dimensions.height}`}
      />
    </div>
  );
};

export default PortfolioChart;
