import React, { FC, useState } from "react";
import { useNavigate } from "react-router-dom";

function generateTableTsx(companyBalances: { [key: string]: string }, companyData: any, navigate: any) {
  // Filter the rows to include only those with positive balances
  const rows = companyData.map((company: any) => {
    if (company.title in companyBalances && Number(companyBalances[company.title]) > 0) {
      return (
        <tr className="border-t cursor-pointer" key={company.title} onClick={() => navigate(company.uri)}>
          <td className="py-2 flex flex-row items-center">
            <div className="p-1 mr-2">
              <img src={company.image} alt={company.title} />
            </div>
            <div className="flex flex-col">
              <div className="text-xs">{company.title}</div>
              <div className="text-xs">{company.shareType}</div>
            </div>
          </td>
          <td className="py-1 text-xs">
            $
            {company.sharePrice < 0
              ? parseFloat(company.sellSharePrice).toFixed(2)
              : parseFloat(company.sharePrice).toFixed(2)}
          </td>
          <td className="py-1 text-xs">{parseFloat(companyBalances[company.title])}</td>
          <td className="py-1 text-xs">
            $
            {company.sharePrice < 0
              ? (parseFloat(company.sellSharePrice) * parseFloat(companyBalances[company.title])).toFixed(2)
              : (parseFloat(company.sharePrice) * parseFloat(companyBalances[company.title])).toFixed(2)}
          </td>
          <td className="py-1 text-xs">
            <button 
            className="border rounded px-2 py-1"
             style={{ borderColor: '#436FE6', color: '#436FE6' }}
              >
              Trade
                </button>
            </td>
        </tr>
      );
    } else {
      return null; // Exclude rows that do not meet the condition
    }
  });

  // Filter out `null` values from rows
  const filteredRows = rows.filter(Boolean);

  // If no rows exist, return a "No allocations yet" message
  if (filteredRows.length === 0) {
    return (
      <tr>
        <td colSpan={5} className="py-2 text-center text-gray-400">
          No Positions Yet
        </td>
      </tr>
    );
  }

  // Return the filtered rows
  return filteredRows;
}

interface AssetTableProps {
  companyBalances: any;
  companyData: any;
}

const AssetTable: FC<AssetTableProps> = ({ companyBalances, companyData }) => {
  const navigate = useNavigate();
  const tableTsx = generateTableTsx(companyBalances, companyData, navigate);
  return (
    <table className="min-w-full table-auto border-collapse">
      <thead>
        <tr>
          <th className="text-left py-2 text-gray-400 text-xs" style={{ width: "35%" }}>
            Firm
          </th>
          <th className="text-left py-2 text-gray-400 text-xs" style={{ width: "20%" }}>
            Share Price
          </th>
          <th className="text-left py-2 text-gray-400 text-xs" style={{ width: "20%" }}>
            Quantity Held
          </th>
          <th className="text-left py-2 text-gray-400 text-xs" style={{ width: "25%" }}>
            Estimated Value
          </th>
        </tr>
      </thead>
      <tbody>{tableTsx}</tbody>
    </table>
  );
};
export default AssetTable;
