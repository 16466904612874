import React, { FC, useState } from "react";
import { useNavigate } from "react-router-dom";

function generateTableTsx(companyData: any, navigate: any) {
  return companyData.map((investment: any) => {
    return (
      <tr className="border-t cursor-pointer" key={investment.title} onClick={() => navigate(investment.uri)}>
        <td className="py-2 flex flex-row items-center">
          <div className="p-1 mr-2">
            <img src={investment.image} />
          </div>
          <div className="flex flex-col">
            <div className="text-l"> {investment.title}</div>
            <div className="text-xs"> {investment.shareType}</div>
          </div>
        </td>
        <td className="py-2">
          <div className="flex flex-col">
            <div className="">
              ${(investment.sharePrice < 0 ? investment.sellSharePrice : investment.sharePrice).toFixed(2)}
            </div>
          </div>
        </td>
      </tr>
    );
  });
}
interface MarketTableProps {
  companyData: any;
}
const MarketTable: FC<MarketTableProps> = ({ companyData }) => {
  const navigate = useNavigate();
  const tableTsx = generateTableTsx(companyData, navigate);

  return (
    <table className="min-w-full table-auto border-collapse">
      <thead>
        <tr>
          <th className="text-left py-2 text-gray-400 text-xs" style={{ width: "50%" }}>
            Firm
          </th>
          <th className="text-left py-2 text-gray-400 text-xs" style={{ width: "50%" }}>
            Share Price
          </th>
        </tr>
      </thead>
      <tbody>{tableTsx}</tbody>
    </table>
  );
};
export default MarketTable;
