import React, { FC, useState } from "react";

const vitals: VitalsType = {
  ceo: "David Ripley",
  founded: 2011,
  employees: 2900,
  headquarters: "San Fransicso, CA"
};

interface VitalsType {
  ceo: string;
  founded: number;
  employees: number;
  headquarters: string;
}

function generateTableTsx() {
  return Object.entries(vitals).map(([key, value]) => {
    return (
      <td key={key} className="py-2 items-center">
        {value}
      </td>
    );
  });
}

const VitalsTable: FC = () => {
  const tableTsx = generateTableTsx();

  return (
    <table className="min-w-full table-auto border-collapse">
      <thead>
        <tr>
          <th className="text-left py-2 text-gray-400 text-xs" style={{ width: "25%" }}>
            CEO
          </th>
          <th className="text-left py-2 text-gray-400 text-xs" style={{ width: "25%" }}>
            Founded
          </th>
          <th className="text-left py-2 text-gray-400 text-xs" style={{ width: "25%" }}>
            Employees
          </th>
          <th className="text-left py-2 text-gray-400 text-xs" style={{ width: "25%" }}>
            Headquarters
          </th>
        </tr>
      </thead>
      <tbody>
        <tr className="cursor-pointer border-t">{tableTsx}</tr>
      </tbody>
    </table>
  );
};
export default VitalsTable;
