import React, { FC, useState } from "react";

interface ActionSelectorProps {
  action: string;
  setAction: (value: string) => void;
}

const ActionSelector: FC<ActionSelectorProps> = ({ action, setAction }) => {
  return (
    <div style={{ backgroundColor: "#F3F3F3" }} className="flex flex-row p-1 text-center mb-2">
      <div
        style={{ backgroundColor: action === "buy" ? "white" : "#F3F3F3" }}
        className="w-1/2"
        onClick={() => setAction("buy")}
      >
        Buy
      </div>
      <div
        onClick={() => setAction("sell")}
        style={{ backgroundColor: action === "sell" ? "white" : "#F3F3F3" }}
        className="w-1/2"
      >
        Sell
      </div>
    </div>
  );
};
export default ActionSelector;
