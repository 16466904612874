import React, { FC, useState } from "react";

interface DenominationSelectorProps {
  denomination: string;
  setDenomination: (value: string) => void;
}

const DenominationSelector: FC<DenominationSelectorProps> = ({ denomination, setDenomination }) => {
  return (
    <div style={{ backgroundColor: "#D0DBF9" }} className="flex flex-row w-1/2 p-1 items-center text-center">
      <div
        onClick={() => {
          setDenomination("shares");
        }}
        style={{ background: denomination == "shares" ? "white" : "#D0DBF9" }}
        className="w-1/2 h-full flex justify-center items-center text-xs leading-relaxed"
      >
        Shares
      </div>
      <div
        onClick={() => {
          setDenomination("dollars");
        }}
        style={{ background: denomination == "dollars" ? "white" : "#D0DBF9" }}
        className="w-1/2 h-full flex justify-center items-center text-xs leading-relaxed"
      >
        Dollars
      </div>
    </div>
  );
};
export default DenominationSelector;
