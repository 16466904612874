import { useTranslation } from "react-i18next";

const arr = [
  `Get yourself a small piece of something big.  We are Prinx, \
an investment exchange where you can get access to shares of pre-ipo companies, at no minimum`,
  `Prinx is an exchange where \
 you can get access to shares of pre-ipo companies, at no minimum. \
Get yourself a small piece of something big.`
];
export function Roll_A_B(t: any) {
  console.log(t, t("ABTest_A"));
  return Math.random() < 0.5 ? { str: t("ABTest_A"), ab: "a" } : { str: t("ABTest_B"), ab: "b" };
}
