import React, { FC, useContext, useEffect, useState } from "react";
import { CompanyDataType } from "constants/constants";
import PortfolioChart from "./PortfolioChart";
import BlockChainContext from "blockchainContext";
import SharePriceContext from "sharePriceContext";
import { formatToDollar } from "common";
import { getPortfolioData } from "../../calls/index";

const stockData = [
  { date: new Date("2023-01-01"), price: 100 },
  { date: new Date("2023-02-01"), price: 110 },
  { date: new Date("2023-03-01"), price: 105 },
  { date: new Date("2023-04-01"), price: 115 },
  { date: new Date("2023-05-01"), price: 120 },
  { date: new Date("2023-06-01"), price: 130 }
];
function determineValue(balances: { [key: string]: string }, companyData: CompanyDataType[]) {
  const aggregatedPrice = Object.entries(balances).reduce((total, [key, value]) => {
    const company = companyData.find((c: CompanyDataType) => c.title === key); // Find the matching company by title
    if (company) {
      const multiplier = parseFloat(value as string); // Convert value to a number
      const sharePrice = company.sharePrice; // Get sharePrice from CompanyData
      return total + multiplier * sharePrice; // Add the product to the total
    }
    return total; // If no match is found, return the total unchanged
  }, 0);
  return `${formatToDollar(aggregatedPrice.toString())}`;
  //return values.reduce((accumulator: any, item: any) => accumulator + item.sharePrice, 0);
}
const PortfolioSummary: FC = () => {
  const [data, setData] = useState(null);
  const { smartWalletAddress } = useContext(BlockChainContext);
  const { companyBalances } = useContext(BlockChainContext);
  const { companyData } = useContext(SharePriceContext);
  const value = determineValue(companyBalances, companyData);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const stuff = await getPortfolioData(smartWalletAddress);
        console.log(stuff); // Process the data
        setData(stuff);
      } catch (error) {
        console.error("Error fetching portfolio data:", error);
      }
    };
    if (smartWalletAddress) {
      fetchData();
    }
  }, [smartWalletAddress]);

  return (
    <div className="bg-white flex-col h-full w-full">
      <div className="p-4">
        <div className="text-m"> Portfolio Value </div>
        <div className="text-2xl mt-4"> {value} </div>
        {/* <div className="flex flex-row mt-2">
          <div className="text-green-700"> +$102(0.9%)</div>
          <div className="text-black pl-2"> Today </div>
        </div> */}
      </div>
      <PortfolioChart data={data ? data : stockData} />
    </div>
  );
};
export default PortfolioSummary;
