import React from "react";
import { ethers } from "ethers";
import InvestmentCard from "./InvestmentCard"; // Import the card component
import ModalWrapper from "../Modals/ModalWrapper";

import deposit_ABI from "../../contracts/deposits.json";
import { useContext, useState } from "react";

import { PrimaryInvestmentType } from "types";
import { useSmartWallets } from "@privy-io/react-auth/smart-wallets";
import { PrimaryInvestments } from "../../constants/constants";
import { sendDeposit } from "./transactions";

import BlockChainContext from "blockchainContext";
import { Link } from "react-router-dom";
import SharePriceContext from "sharePriceContext";

interface InvestmentCardProps {
  investment: PrimaryInvestmentType;
  handleOpenModal: () => void;
  inputValue: number | null;
}

const GridInvestmentModals: React.FC<{ investments: PrimaryInvestmentType[] }> = ({ investments }) => {
  const [isVisible, setVisible] = React.useState(false); // Control rendering of ModalWrapper
  const [isAnimating, setIsAnimating] = React.useState(false);
  const [selectedInvestment, setSelectedInvestment] = React.useState<PrimaryInvestmentType | null>(investments[0]);
  const [inputValues, setInputValues] = React.useState<{ [key: string]: number | string }>({}); // Allow empty string
  const [isLoading, setIsLoading] = useState(false);

  const handleOpenModal = (investment: any) => {
    setSelectedInvestment(investment);
    setVisible(true); // Render modal in DOM
    setTimeout(() => setIsAnimating(true), 10);
  };

  const closeModal = () => {
    setIsAnimating(false); // Start closing animation
    setVisible(false);
    setTimeout(() => {
      setVisible(false); // Remove from DOM after animation completes
      setSelectedInvestment(investments[0]);
    }, 250); // Delay to ensure animation completes
  };

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-4">
      {isLoading ? (
        <div>Loading investments...</div>
      ) : (
        investments.map((investment, index) => (
          <Link key={`primary-${index}`} to={investment.uri}>
            <InvestmentCard
              key={`${investment.wallet_address}-${investment.deposited}-${Date.now()}`}
              investment={{ ...investment }} // Pass a new object to force re-render
              handleOpenModal={() => handleOpenModal(investment)}
            />
          </Link>
        ))
      )}
      {isVisible && selectedInvestment && (
        <ModalWrapper
          investment={selectedInvestment}
          isAnimating={isAnimating}
          inputValue={Number(inputValues[selectedInvestment.title || "0"]) || 0}
          action="buy"
          func={async () => {
            setVisible(false);
          }}
          onClose={closeModal}
        />
      )}
    </div>
  );
};

export default GridInvestmentModals;
